.page-contact {
    .title {
        text-align: center;
        padding-top: 15px;

        .is-h1 {
            margin-bottom: $default-margin * 2;
        }
    }

    .wrapper {
        &--large {
            @include media-breakpoint-up(xl) {
                max-width: 1360px;
            }
        }
    }

    .is-h2 {
        font-size: 35px;
        line-height: 45px;
        font-weight: 600;
    }

    .is-h3 {
        font-size: 24px;
        line-height: 30px;
    }
}

.block {
    &--events {
        background: $color-green;
        padding: 40px 0;
        margin-bottom: $default-block-margin;

        .is-h2 {
            text-transform: uppercase;
        }
    }
}

.contact {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: $default-margin * 2;

    &__image {
        @include make-col(4);
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 80px;
        position: relative;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        img {
            border-radius: 181px;
            transform: rotate(20deg);
            margin-left: -100px;
            width: 485px;
            max-width: unset;
        }
    }

    &__form {
        @include make-col(3.5);
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-down(lg) {
            @include make-col(6);
        }

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        p {
            &.error {
                background: $color-error;
                padding: 15px;
                border-radius: 6px;
                color: $color-white;
                font-size: 16px;
                line-height: normal;
            }
        }

        label {
            &.error {
                background: $color-error;
                color: $color-white;
                padding: 8px 15px;
                font-size: 14px;
                line-height: normal;
                border-radius: 4px;
                margin-top: 5px;
            }
        }

        .button {
            width: 100%;
            padding-top: 16px;
            padding-bottom: 16px;
            background: $color-green;
            font-weight: 600;

            &:hover,
            &:focus {
                color: $color-white;
                text-decoration: none;
                background-color: darken($color-green, 10%);
                border-color: darken($color-green, 10%);
            }

        }

        .field {
            margin-bottom: $default-margin;
        }

        input[type="text"],
        input[type="email"],
        textarea {
            width: 100%;
            border-radius: 20px;
            border: 1px solid $color-green;
            padding: 10px 15px;
        }
    }

    &__content {
        @include make-col(4.5);
        padding-left: 10px;
        padding-right: 10px;

        @include media-breakpoint-down(lg) {
            @include make-col(6);
        }

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }


}

.events {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    &__title {
        @include make-col(4);
        padding: 0 10px;
        padding-right: 30px;

        @include media-breakpoint-down(lg) {
            @include make-col(12);
        }

        .is-h2 {
            color: $color-white;
        }
    }

    &__content {
        @include make-col(8);
        padding: 0 10px;
        position: relative;

        @include media-breakpoint-down(lg) {
            @include make-col(12);
        }

        //scss-lint:disable ImportantRule
        .tns-outer {
            height: 100%;
            justify-content: center;
            position: relative;
            padding-top: 30px !important;
            padding-bottom: 30px !important;

            .tns-controls {
                position: absolute;
                bottom: 0;
                right: 15px;

                button {
                    @include icon(chevron, $color-white, 12px, 19px);

                    &[disabled] {
                        @include icon(chevron, $color-white, 12px, 19px);
                        opacity: .4;
                    }
                }
            }
        }
        //scss-lint:enable ImportantRule
    }
}

.events-carousel {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    &.tns-slider {
        display: block;

        #{ $self }__item {
            @include make-col(12);
        }
    }

    &__item {
        @include make-col(3);
        padding: 0 10px;

        .event-date {
            display: block;
            color: $color-white;
            font-size: 18px;
            line-height: 30px;
        }

        .is-h3 {
            color: $color-white;
        }
    }
}
