.footer {
    position: relative;
    flex-shrink: 0;
    background-color: $color-blue;
    color: $color-white;
    font-size: 16px;
    line-height: 22px;

    &__top {
        display: block;
        padding: 40px 0 0;

        .is-h3 {
            margin: 0 0 20px;
            color: $color-white;
            font-size: 16px;
            line-height: 18px;
            font-weight: 600;
            text-transform: uppercase;

            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }
    }

    &__logo {
        @extend .container;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 60px auto;
    }

    &__bottom {
        @extend .container;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        line-height: 24px;
        padding: 20px 15px 0;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
        }
    }

    .copyright {
        display: inline-block;
        margin: 0 0 20px;
    }

    .navigation {
        &--footer {
            display: block;
            width: 100%;
            margin: 0 0 20px;

            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    margin-bottom: 60px;
                }

                li {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .social-share {
        margin: -4px 0 10px auto;

        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}

.footer-doormat {
    @include make-row();
    margin: 0 -15px;

    &__item {
        @include make-col(2);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(6);
            margin-bottom: 60px;
        }

        @include media-breakpoint-down(sm) {
            @include make-col(6);
        }

        &--social-follow {
            @include make-col(3.5);

            @include media-breakpoint-down(md) {
                @include make-col(12);
                @include make-col-push(0);
            }
        }

        &--certified {
            text-align: center;
            flex: 0 0 8%;

            @include media-breakpoint-down(md) {
               @include make-col(12);
            }
        }

        &--3 {
            @include make-col(1.5);
            padding: 0 15px;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }
        }
    }
}
