// Ingredients block

.block {
    &--ingredients {
        margin: 0 0 110px;
        overflow: hidden;

        .btn--interest {
            font-size: 23px;
        }
    }
}

.ingredients {
    a:not(.btn) {

        &:hover {
            color: $color-green;
            text-decoration: none;
        }
    }

    .pagination {
        a {
            &:hover {
                color: $color-white;
            }
        }
    }

    .is-h1 {
        font-size: 70px;
        line-height: 74px;
        font-weight: 600;

        @include media-breakpoint-up(md) {
            margin-top: 40px;
        }
    }

    &__hero {
        @include make-row();
        position: relative;

        &-text {
            @include make-col(7);
            font-size: 32px;
            line-height: 36px;
            z-index: 1;
            text-shadow: 2px 2px 2px $color-white;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }

        &-image {
            @include make-col(5);

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            img {
                margin-top: 65px;
                transform: none;
                border-radius: 9999px;
                max-width: unset;

                @include media-breakpoint-down(sm) {
                    transform: none;
                    max-width: 100%;
                    margin-top: 25px;
                }
            }
        }
    }

    &__list {
        @include make-row();
        margin-top: 70px;

        @include media-breakpoint-down(sm) {
            margin-top: 20px;
        }

        &-filters {
            @include make-col(2.5);
            @include make-col-push(.5);
            position: relative;

            @include media-breakpoint-down(md) {
                @include make-col(3.5);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                @include make-col-push(0);
            }

            .filter-heading {
                font-family: $narrow-font;
                margin: 0 0 10px;
                font-size: 32px;
                line-height: 34px;
                position: relative;

                .reset-filters {
                    font-family: $text-font;
                    font-size: 14px;
                    line-height: 16px;
                    position: absolute;
                    right: 0;
                    top: 12px;
                    color: $color-green;

                    @include media-breakpoint-down(sm) {
                        right: 45px;
                        top: 7px;
                    }

                    &:hover {
                        color: $color-green;
                        text-decoration: underline;
                    }
                }
            }

            .filters {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .filter-toggle {
                h4 {
                    margin: 0;
                    text-transform: uppercase;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 18px;
                    border-radius: 20px;
                    padding: 8px 15px 6px;
                    background-color: $color-green;
                    color: $color-white;
                    position: relative;
                    cursor: default;

                    &::after {
                        @include icon(arrow, $color-white, 10px, 7px);
                        content: '';
                        width: 10px;
                        height: 7px;
                        position: absolute;
                        right: 10px;
                        top: 12px;
                        transition: all .3s;
                    }

                    &.hidden {
                        &::after {
                            transform: rotate(180deg);
                            transition: all .3s;
                        }
                    }
                }
            }

            fieldset {
                font-family: $mono-font;

                ul {
                    li {
                        padding: 0;
                        background: none;
                    }
                }

                a {
                    font-size: 14px;
                    line-height: 14px;
                    position: relative;
                    padding-left: 25px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 1px;
                        width: 16px;
                        height: 16px;
                        border: 1px solid $color-green;
                        border-radius: 50%;
                    }
                }

                .checkbox {
                    input {
                        display: none;
                    }

                    label {
                        font-size: 14px;
                        line-height: 16px;
                        position: relative;
                        padding-left: 25px;
                        cursor: pointer;

                        &:hover {
                            color: $color-green;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 2px;
                            width: 14px;
                            height: 14px;
                            border: 1px solid $color-green;
                        }
                    }
                }

                .active {
                    a {
                        &::after {
                            content: '';
                            position: absolute;
                            left: 2px;
                            top: 3px;
                            width: 12px;
                            height: 12px;
                            background: $color-green;
                            border-radius: 50%;
                        }
                    }

                    &.checkbox {
                        label {
                            &::after {
                                @include icon(check, $color-green, 11px, 8px);
                                content: '';
                                position: absolute;
                                left: 2px;
                                top: 5px;
                                width: 11px;
                                height: 8px;
                            }
                        }
                    }
                }
            }
        }

        .overview-active-filters {
            @include make-col(9);
            @include make-col-pull(-3);
            margin-bottom: 45px;

            @include media-breakpoint-down(md) {
                @include make-col(8);
                @include make-col-pull(-4);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                @include make-col-pull(0);
                margin-bottom: 15px;
            }

            a {
                @include icon(close, $color-green, 10px, 10px);
                font-family: $mono-font;
                background-position: calc(100% - 10px) center;
                display: inline-block;
                font-size: 14px;
                line-height: 16px;
                border-radius: 20px;
                padding: 6px 30px 4px 15px;
                margin-right: 10px;
                border: 1px solid $color-green;
                color: $color-green;
                cursor: pointer;

                &:hover {
                    @include icon(close, $color-white, 10px, 10px);
                    background-color: $color-green;
                    background-position: calc(100% - 10px) center;
                    color: $color-white;
                }
            }
        }

        &-products {
            @include make-col(9);
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;

            @include media-breakpoint-down(md) {
                @include make-col(8);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            &-item {
                @include make-col(4);
                text-align: center;

                @include media-breakpoint-down(md) {
                    @include make-col(6);
                }

                a {
                    &:hover {
                        h3 {
                            color: $color-green;
                        }
                    }
                }

                img {
                    border-radius: 50%;
                    width: 90%;
                }

                h3 {
                    font-family: $mono-font;
                    margin: 15px 0 0;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 14px;
                    line-height: 16px;
                }

                p {
                    font-family: $mono-font;
                    margin: 0;
                    font-size: 14px;
                    line-height: 16px;
                }
            }

            .pagination {
                width: 100%;
                margin-top: 35px;
            }
        }
    }

    &__filter-toggle-btn {
        background-color: $color-green;
        color: $color-white;
        margin-bottom: 25px;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__filter-close-btn {
        @include icon(close, $color-green, 20px, 20px);
        border: 1px solid $color-green;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0;
        z-index: 1;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}
