// Title block

.block {
    &--title {
        margin: 0;
    }
}

.title {
    $self: &;
    display: flex;
    flex-direction: column;

    &__content {
        text-align: center;

        .text-color {
            &--dark {
                color: $color-black;
            }

            &--light {
                color: $color-gray;
            }
        }
    }

    &--large {
        #{ $self }__content {
            .is-h1 {
                font-size: 58px;
                line-height: 68px;
            }

            .is-h2 {
                font-size: 48px;
                line-height: 58px;
            }
        }
    }

    &--medium {
        #{ $self }__content {
            .is-h1 {
                font-size: 48px;
                line-height: 58px;
            }

            .is-h2 {
                font-size: 38px;
                line-height: 48px;
            }
        }
    }

    &--small {
        #{ $self }__content {
            .is-h1 {
                font-size: 38px;
                line-height: 48px;
            }

            .is-h2 {
                font-size: 28px;
                line-height: 38px;
            }
        }
    }
}

.block--title + .block--image-and-text {
    margin-top: -20px;
}
