.block--image-and-text {

    &.bg-gray {
        background-color: $color-gray-2;
    }

    .wrapper {
        padding: 2rem 0;

        @include media-breakpoint-down(md) {
            margin-bottom: 0;
            margin-top: 0;
        }

        .d-flex {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            gap: 3rem;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                gap: 1rem;

                .richtext {
                    padding: 0 1rem;
                }
            }

            &.reverse {
                flex-direction: row-reverse;

                @include media-breakpoint-down(md) {
                    flex-direction: column-reverse;
                }
            }

            image {
                flex-shrink: 0;
                padding: 2rem;
            }
        }
    }
}
