// Image block

.block {
    &--image-block {
        margin: 0;
    }
}

.image-block {
    $self: &;
    position: relative;
    margin: 0 0 40px;

    &__title {
        text-align: center;
    }

    &__image {
        position: relative;

        a {
            display: block;

            &.video {
                position: relative;

                &::after {
                    @include icon(play, $color-white, 20px, 20px);
                    position: absolute;
                    left: 10px;
                    bottom: 10px;
                    display: block;
                    width: 30px;
                    height: 30px;
                    content: '';
                }

                &:hover,
                &:focus {
                    &::after {
                        @include icon(play, $color-blue, 20px, 20px);
                    }
                }
            }
        }
    }

    &__buttons {
        text-align: center;
    }

    &__caption {
        font-family: $mono-font;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        margin: 15px 0 0;

        &::before {
            content: '↪ ';
        }
    }

    &--simple {
        #{ $self }__caption {
            text-align: left;
            margin-left: 15px;
        }
    }

    &--simplebig {
        img {
            width: 100%;
        }
    }

    &--fullwidth {
        #{ $self }__image {
            width: 100%;
            margin: 0;
        }
    }

    &--hero {
        margin: 40px 0;

        #{ $self }__image {
            width: 100%;
            margin: 0;
            border-radius: 9999px;
        }
    }
}
