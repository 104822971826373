// Brands block

.block {
    &--product-detail {
        margin: 70px 0;

        @include media-breakpoint-down(md) {
            margin: 40px 0 60px;
        }
    }
}

.product-detail {
    display: flex;
    flex-wrap: wrap;

    &__image {
        @include make-col(5);
        @include make-col-push(2);
        position: sticky;
        top: 60px;
        height: 500px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            @include make-col-push(0);
            height: auto;
            position: static;
        }

        img {
            display: block;
            margin: 0 auto;
            width: 90%;
            border-radius: 50%;
        }
    }

    &__sidebar {
        @include make-col(5);

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        .is-h1 {
            font-size: 50px;
            line-height: 50px;
        }
    }

    &__title {
        margin-bottom: 10px;

        .is-h1,
        .is-h2 {
            margin: 0 0 5px;
            font-weight: 600;
            color: $text-color;
        }
    }

    &__subtitle {
        font-family: $mono-font;
        font-size: 14px;
        line-height: 14px;
    }

    &__list {
        font-family: $mono-font;
        margin-bottom: 35px;

        &-item {
            display: flex;
            flex-wrap: wrap;
            font-size: 14px;
            line-height: 14px;
            border-bottom: 1px solid $text-color;
            padding: 15px 0 10px;

            &-label {
                @include make-col(5);
                font-weight: 600;
            }

            &-value {
                @include make-col(7);
            }
        }
    }

    &__buttons {
        margin-top: 45px;
    }

    &__download {
        margin-bottom: 15px;
    }
}
