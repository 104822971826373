// FAQ block

.block {
    &--faq {
        margin: 0;
    }
}

.faq {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__buttons {
        text-align: center;
    }
}

.faq-overview {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    &__item {
        @include make-col(4);
        display: flex;
        flex-direction: column;
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    .faq-group & {
        flex-direction: row;

        &__item {
            @include make-col(12);
        }
    }
}

.faq-question {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    margin: 0 0 40px;

    &::before {
        position: absolute;
        left: 50%;
        right: 50%;
        bottom: 0;
        display: block;
        height: 2px;
        opacity: 0;
        content: '';
        z-index: 1;
        background-color: $color-blue;
        transition: all .3s ease-in-out;
    }

    &:hover,
    &:focus {
        &::before {
            left: 0;
            right: 60px;
            opacity: 1;
        }
    }

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 35px;
        display: block;
        height: 1px;
        content: '';
        background-color: transparent;
    }

    &__title {
        position: relative;
        display: flex;
        margin: 0;
        padding: 0 70px 0 0;
        cursor: pointer;
        min-height: 50px;

        &::after {
            @include icon(arrow, $color-white, 20px, 20px);
            position: absolute;
            top: 5px;
            right: 10px;
            display: block;
            width: 40px;
            height: 40px;
            content: '';
            background-color: $color-blue;
            border-radius: 50%;
            transform: rotate(0deg);
            transition: all .3s;

            @include media-breakpoint-down(sm) {
                right: 0;
            }
        }

        .is-h6 {
            color: $text-color;
            margin: 0 0 20px;
        }
    }

    &__content {
        display: none;
        flex: 1;
        padding: 0 70px 0 0;

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        .richtext {
            margin: 0 0 20px;
        }

        p {
            margin: 0 0 20px;
        }
    }

    &.active {
        #{ $self } {
            &__title {
                &::after {
                    transform: rotate(-180deg);
                }
            }

            &__content {
                display: block;
            }
        }
    }
}
