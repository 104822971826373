form {
    label {
        display: block;
        cursor: pointer;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        user-select: none;

        &.form-control {
            &:not([size]):not([multiple]) {
                height: 57px;
            }
        }
    }

    .help-block {
        margin: 8px 0 0;
    }
}

.form {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;

    .form-group {
        margin: 0 0 10px;

        label {
            margin: 0 0 10px;
        }

        > label {
            font-weight: 600;
        }

        &.has-error {
            .form-control {
                border-color: $color-red;
            }

            .help-block {
                color: $color-red;
            }
        }
    }

    .form-control {
        font-family: $text-font;
        font-size: $text-size;
        line-height: $line-height;
        height: auto;
        padding: 5px 15px;
        border: 1px solid $color-green;
        background-color: $color-white;
        border-radius: 0;

        &:focus {
            border-color: $color-gray;
            background-color: $color-white;
            box-shadow: none;
        }

        &--textarea {
            height: 120px;
        }

        &--select {
            @include icon(select, $color-black, 20px, 6px);
            background-position: center right 10px;
            background-color: $color-white;
        }

        &--check {
            position: relative;
            padding: 0 0 0 28px;
            margin: 0 0 10px;
            border: 0;
            border-radius: 0;
            background-color: transparent;

            label {
                margin: 0;
            }

            &:last-child {
                margin: 0;
            }

            input[type="radio"],
            input[type="checkbox"] {
                display: none;

                + i {
                    position: absolute;
                    left: 0;
                    top: 7px;
                    display: block;
                    width: 18px;
                    height: 18px;
                    border: 2px solid $color-gray;

                    &::before {
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        display: none;
                        width: 12px;
                        height: 12px;
                        content: '';
                        background-color: $color-blue;
                    }
                }

                &:checked {
                    + i {
                        &::before {
                            display: block;
                        }
                    }
                }
            }

            input[type="radio"] {
                + i {
                    border-radius: 50%;

                    &::before {
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .input-group {
        display: flex;

        .form-control {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            margin-right: -20px;
        }
    }
}

.form-horizontal {
    .form-group {
        @include make-row();
        margin: 0 0 10px;

        label {
            margin: 0;
        }

        > label {
            @include make-col(3);
            padding: 11px 10px 11px 0;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
                margin: 0 0 10px;
            }

            + .form-control-wrapper {
                padding: 0 0 0 10px;

                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }
        }
    }

    .form-control-wrapper {
        @include make-col(9);

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}

.form-inline {
    flex-direction: row;
    align-items: baseline;
    margin-left: -10px;
    margin-right: -10px;

    .form-group {
        flex: 1;
        flex-direction: column;
        padding: 0 10px;
        margin: 0 0 10px;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
        }

        &:last-child {
            flex: 0;
        }

        &--error {
            flex: 0 0 100%;
            text-align: center;

            .help-block {
                color: $color-red;
                margin: 0;
            }
        }

        .form-control,
        .help-block {
            width: 100%;
        }

        .btn {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.form-search {
    position: relative;

    .btn {
        background-color: $color-green;

        @include media-breakpoint-down(md) {
            @include icon(search, $color-white, 20px, 20px);
            width: 120px;
            padding: 0;
            font-size: 0;
        }
    }
}

// scss-lint:disable ImportantRule
.hbspt-form {
    fieldset {
        padding: 0 !important;
        max-width: none !important;
    }

    .hs-main-font-element {
        display: none;
    }

    .hs-form-field {
        @extend .form-group;

        .input {
            margin: 0 !important;
        }
    }

    .form-columns-1 {
        .hs-input {
            width: 100% !important;
        }
    }

    .form-columns-2 {
        margin: 0 -10px;

        .form-group {
            width: 50%;
            padding: 0 10px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .hs-error-msgs {
        .hs-error-msg {
            @extend .help-block;
            color: $color-red !important;
        }
    }

    .no-list,
    .inputs-list {
        margin: 0;
    }

    .hs-input {
        @extend .form-control;

        &.error {
            border-color: $color-red;
        }
    }

    .hs-button {
        @extend .btn;
    }

    .hs-form-booleancheckbox-display {
        &:last-of-type {
            margin: 0;
        }

        input[type="radio"],
        input[type="checkbox"] {
            display: none;

            + span {
                position: relative;
                padding: 0 0 0 25px;
                margin: 0 0 10px;

                &::after {
                    position: absolute;
                    left: 0;
                    top: 5px;
                    display: block;
                    width: 16px;
                    height: 16px;
                    content: '';
                    border: 2px solid $color-blue;
                    margin: 0;
                }

                &::before {
                    position: absolute;
                    top: 8px;
                    left: 3px;
                    display: none;
                    width: 10px;
                    height: 10px;
                    content: '';
                    background-color: $color-blue;
                }
            }

            &:checked {
                + span {
                    &::before {
                        display: block;
                    }
                }
            }
        }

        input[type="radio"] {
            + span {
                &::after {
                    border-radius: 50%;
                }

                &::before {
                    border-radius: 50%;
                }
            }
        }
    }
}
// scss-lint:enable ImportantRule

.contact-form-ac {
    margin: 0 0 20px;
    max-width: 390px;

    .introtext {
        max-width: 250px;
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 30px;
            max-width: 100%;
        }
    }

    .form-control {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        color: black;
    }
    
    .btn {
        font-size: 16px;
        line-height: 18px;
        border-radius: 20px;
    }

    // ActiveCompain embed form
    .form-element {
        // width: calc(100% - 105px);
        // margin-right: -20px;
        width: 100%;
        //display: inline-block;
        position: relative;
        margin-bottom: 5px;

        &:nth-of-type(1) {
            display: inline-block !important;
        }

        label, ._form-title {
            display: block;
            font-size: 35px;
            line-height: 45px;
            font-weight: 600;
            font-family: "GT America Condensed", sans-serif;
            color: #37ad72;
            //margin-bottom: 20px
        }

        .form-control, select {
            &::placeholder {
                color: black;
            }

            height: auto;
            border: 1px solid $color-green;
            padding: 7px 15px;
            // border-top-left-radius: 20px;
            // border-bottom-left-radius: 20px;
            border-radius: 20px;
            width: 100%;
            margin-bottom: 20px;
        }

        select {
            @include icon(arrow, #37ad72, 40px, 40px);
            background-position: calc(100% - 20px) 12px;
            background-size: 19px;
        }

        label {
            color: #37ad72;
            font-size: 23px;
            line-height: 33px;
            font-weight: 600;
        }
    }

    .button-wrapper {
        display: inline-block;
        position: relative;
        width: 100%;

        button {
            width: 100%;
            padding: 16px;
        }
    }

    .form-thank-you {
        color: $color-white;
        text-align: center;
        line-height: 22px;
        font-size: 16px;
        width: 80%;
        margin: 0 auto 25px;
        border: 1px solid;
        padding: 5px 10px;
    }
}

.subscribe-form {
    margin: 0 0 20px;
    max-width: 390px;

    .introtext {
        max-width: 250px;
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 30px;
            max-width: 100%;
        }
    }

    .form-control {
        font-size: 16px;
        line-height: 18px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    .btn {
        font-size: 16px;
        line-height: 18px;
        border-radius: 20px;
    }

    // ActiveCompain embed form
    .form-element {
        // width: calc(100% - 105px);
        // margin-right: -20px;
        width: 100%;
        display: inline-block;
        position: relative;
        margin-bottom: 5px;

        label {
            display: none;
        }

        .form-control {
            height: auto;
            border: 1px solid $color-green;
            padding: 7px 15px;
            // border-top-left-radius: 20px;
            // border-bottom-left-radius: 20px;
            border-radius: 20px;
        }
    }

    .button-wrapper {
        display: inline-block;
        position: relative;
    }

    .form-thank-you {
        color: $color-white;
        text-align: center;
        line-height: 22px;
        font-size: 16px;
        width: 80%;
        margin: 0 auto 25px;
        border: 1px solid;
        padding: 5px 10px;
    }
}

::-webkit-input-placeholder {
    color: $color-black;
}

::-moz-placeholder {
    color: $color-black;
}

:-ms-input-placeholder {
    color: $color-black;
}

select::-ms-expand {
    display: none;
}

select option[disabled] {
    display: none;
}
