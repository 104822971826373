// Specifications block

.block {
    &--specifications {
        margin: 0;
    }
}

.specifications {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__buttons {
        text-align: center;
    }
}

.specifications-overview {
    @include make-row();
    margin: 0 -15px;

    &__item {
        @include make-col(3);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}

.specification {
    display: flex;
    flex-direction: column;

    .is-h2,
    .is-h3 {
        margin: 0 0 10px;
    }

    .list {
        margin: 0 0 10px;
    }
}
