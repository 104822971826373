// About block

.block {
    &--about {
        margin: 0 0 110px;
    }
}

.about {
    display: flex;
    flex-wrap: wrap;

    &__image {
        @include make-col(4.5);
        text-align: center;

        @include media-breakpoint-down(lg) {
            @include make-col(3);
        }

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        img {
            width: 100%;
            border-radius: 50%;

            @include media-breakpoint-down(md) {
                width: 80%;
            }
        }
    }

    &__content {
        @include make-col(7.5);

        @include media-breakpoint-down(lg) {
            @include make-col(9);
        }

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        .richtext {
            padding: 80px 0 0 40px;

            @include media-breakpoint-down(md) {
                padding: 40px 0 0;
            }

            button {
                margin-left: 90px;
                text-align: left;

                @include media-breakpoint-down(md) {
                    margin-left: 30px;
                }
            }

            .is-h2 {
                font-size: 70px;
                line-height: 70px;
                margin: 0;
                text-transform: uppercase;

                @include media-breakpoint-down(lg) {
                    font-size: 60px;
                    line-height: 60px;
                }

                @include media-breakpoint-down(md) {
                    font-size: 52px;
                    line-height: 52px;
                }
            }

            ul {
                font-family: $narrow-font;
                font-weight: 600;
                font-size: 37px;
                line-height: 40px;
                color: $color-blue;
                padding-left: 60px;
                margin-top: 40px;

                @include media-breakpoint-down(md) {
                    padding-left: 0;
                }

                li {
                    @include icon(arrow-right, $color-blue, 30px, 30px);
                    background-position: left 5px;
                    padding-left: 35px;
                    list-style: none;

                    @include media-breakpoint-down(md) {
                        background-position: left 4px;
                    }
                }
            }
        }
    }
}
