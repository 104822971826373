.block {
    &--usps {
        margin: 0;
        background: $color-blue;
        color: $white;
        padding: 95px 0 65px;

        @include media-breakpoint-down(md) {
            padding: 45px 0;
        }
    }
}

.usps {
    @include make-row();

    &__title {
        @include make-col(4);

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding: 0 15px;
        }

        .is-h2 {
            font-family: $narrow-font;
            color: $color-white;
            font-size: 50px;
            line-height: 46px;
            max-width: 80%;
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
                max-width: 100%;
                font-size: 60px;
                line-height: 55px;
            }
        }
    }

    &__content {
        @include make-col(8);
        font-family: $narrow-font;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }
}

.usp {
    $self: &;
    margin: 0 0 80px;

    @include media-breakpoint-down(sm) {
        margin: 0 0 $default-margin * 2;
    }

    .icon {
        height: 55px;
        width: 55px;
        margin: 0 15px 0 0;

        &--check {
            @include icon(check, $color-blue, 55px, 55px);
        }
    }

    &__title {
        font-family: $narrow-font;
        font-weight: normal;
        font-size: 70px;
        line-height: 66px;
        margin: 0 0 15px;
        color: $color-white;

        @include media-breakpoint-down(sm) {
            font-size: 55px;
            line-height: 52px;
        }
    }

    &__text {
        font-size: 35px;
        line-height: 35px;
        margin: 0;
        font-weight: 600;
        color: $color-white;
    }

    &:hover {
        text-decoration: none;

        #{ $self }__text {
            text-decoration: underline;
        }
    }
}
