// Brands block

.block {
    &--brands {
        margin: 0 0 100px;
    }
}

.brands {
    display: flex;
    flex-wrap: wrap;

    &__list {
        @include make-col(7.5);
        @include make-col-push(.5);
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            flex-wrap: wrap;
            order: 2;
        }

        &-item {
            flex-grow: .4;
            text-align: center;
            font-size: 20px;
            line-height: 24px;

            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 18px;
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                font-size: 20px;
                line-height: 24px;
            }

            a {
                color: $text-color;
                text-decoration: none;

                &:hover {
                    color: $color-green;
                    text-decoration: none;

                    .is-h3 {
                        color: $color-green;
                    }
                }
            }

            figure {
                margin: 0;
            }

            img {
                width: 100%;
            }

            .is-h3 {
                text-transform: uppercase;
                margin-bottom: 5px;

                @include media-breakpoint-down(md) {
                    font-size: 21px;
                    line-height: 24px;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 30px;
                    line-height: 36px;
                }
            }

            p {
                max-width: 180px;
                margin: 0 auto;
            }
        }
    }

    &__text {
        @include make-col(4);
        padding-top: 100px;

        @include media-breakpoint-down(md) {
            padding-top: 40px;
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding: 0;
        }
    }
}
