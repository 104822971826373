// scss-lint:disable ImportantRule
.block {
    $self: &;
    position: relative;

    &--background,
    &--background-shaped {
        padding: 40px 0 0;
        margin-bottom: 40px !important;
        background-color: $color-gray-2;

        &:last-child {
            margin-bottom: 0 !important;
        }

        + #{ $self }--background,
        + #{ $self }--background-shaped {
            margin-top: -40px !important;
        }
    }

    &--background-shaped {
        &::before,
        &::after {
            position: absolute;
            left: 0;
            width: 100%;
            height: 68px;
            content: '';
            background-color: $color-gray-1;
            z-index: 0;
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }

        > * {
            position: relative;
            z-index: 1;
        }
    }
}
// scss-lint:enable ImportantRule

// Articles

.articles {
    &--list {
        margin: 0 0 20px;
    }
}

.article {
    &--block {
        @include make-row();
        color: $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            .article {
                &--block {
                    &__image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image {
            @include make-col(4);
            padding: 0 15px;
            margin: 0 0 30px;

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }

            img {
                transition: transform .3s;
            }
        }

        &__content {
            @include make-col(8);
            padding: 0 15px;
            margin: 0 0 10px;

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }
    }

    &--reverse {
        justify-content: flex-end;

        .article {
            &--block {
                &__image {
                    order: 2;

                    @include media-breakpoint-down(md) {
                        order: 1;
                    }
                }

                &__content {
                    order: 1;

                    @include media-breakpoint-down(md) {
                        order: 2;
                    }
                }
            }
        }
    }
}

// Gallery

.gallery {
    &--block {
        &__preview {
            position: relative;
            margin: 0 0 20px;

            a {
                display: block;
            }
        }
    }

    &--list {
        @include make-row();
        margin: 0 -10px 10px;

        &__item {
            @include make-col(4);
            padding: 0 10px;
            position: relative;
            margin: 0 0 20px;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            a {
                display: block;
            }
        }
    }
}

// Language popup

.language-popup {
    position: fixed;
    display: none;
    z-index: 99999;

    &.active {
        display: block;
    }

    &::before {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        opacity: .6;
        background: $color-blue;
    }

    &__wrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        display: block;
        width: 700px;
        max-width: 80%;
        color: $color-white;
        padding: 30px 40px 10px;
        transform: translate(-50%, -50%);
        background-color: $color-blue;
        border: 2px solid $color-blue;
        border-radius: 4px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            padding: 20px 20px 50px;
        }

        h2 {
            text-align: center;
            line-height: 38px;
            font-size: 28px;
            text-transform: uppercase;
            color: $color-white;

            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }

        a {
            border-bottom: unset !important;
            background-color: #37ad72 !important;
            border-radius: 50px !important;
        }

        .btn {
            &--close {
                @include icon(close, $color-white, 18px, 18px);
                position: absolute;
                top: -20px;
                right: -20px;
                display: block;
                width: 50px;
                height: 50px;
                font-size: 0;
                background-color: #37ad72;
                border: 0;
                border-radius: 50%;

                &:hover {
                    background-color: darken(#37ad72, 10%);
                }

                @include media-breakpoint-down(sm) {
                    top: auto;
                    bottom: 10px;
                }
            }
        }
    }
}

// Search

.search-results,
.simplesearch-result {
    &--list {
        display: flex;
        flex-direction: column;
        margin: 0 0 20px;

        &__item,
        &__empty-item {
            display: flex;
            flex-direction: column;
        }

        h2 {
            @include icon(arrow-right, $text-color, 40px, 40px);
            background-position: -10px -3px;
            margin-bottom: 10px;
            padding-left: 30px;
            font-size: 32px;
            line-height: 34px;
            color: $text-color;
        }

        .url {
            display: none;
            color: $color-blue;
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 20px;
            font-style: italic;
        }

        .link {
            display: none;
        }
    }
}

.search-result {
    &--block {
        color: $text-color;
        text-decoration: none;
        margin: 0 0 10px;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            h2 {
                @include icon(arrow-right, $color-green, 40px, 40px);
                background-position: -10px -3px;
                color: $color-green;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
        }
    }
}

.search-results-info {
    font-size: 38px;
    line-height: 42px;
    color: $color-green;
    font-weight: bold;
    margin-top: 10px;

    .search-highlight {
        background: none;
    }
}

// Google maps

.maps {
    &--block {
        width: 100%;
        height: 500px;
        margin: -30px 0 30px;

        .map {
            width: 100%;
            height: 100%;
        }
    }
}

// Locations

.locations {
    &--list {
        @include make-row();
        margin: 0 -15px 20px;

        &__item {
            @include make-col(4);
            padding: 0 15px;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(xs) {
                @include make-col(12);
            }
        }
    }
}

.location {
    &--block {
        color: $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            .location {
                &--block {
                    &__image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image {
            margin: 0 0 15px;

            img {
                transition: transform .3s;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            margin: 0 0 10px;
        }
    }
}

// Persons

.persons {
    &--list {
        @include make-row();
        margin: 0 -15px 20px;

        &__item {
            @include make-col(4);
            padding: 0 15px;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(xs) {
                @include make-col(12);
            }
        }
    }
}

.person {
    &--block {
        color: $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            .person {
                &--block {
                    &__image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image {
            margin: 0 0 15px;

            img {
                transition: transform .3s;
                border-radius: 50%;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin: 0 0 10px;
        }
    }
}

// Calendar/Events

.events {
    &--list {
        @include make-row();
        margin: 0 -15px 20px;

        &__item {
            @include make-col(4);
            padding: 0 15px;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(xs) {
                @include make-col(12);
            }
        }
    }
}

.event {
    &--block {
        color: $text-color;
        text-decoration: none;
        display: block;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            .event {
                &--block {
                    &__image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image {
            position: relative;
            margin: 0;
            overflow: hidden;

            img {
                transition: transform .3s;
            }

            time {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                padding: 5px 15px;
                background-color: $color-black;
                color: $color-white;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            margin: 0 0 10px;
            padding: 10px 15px;
            background: $color-white;

            h2 {
                font-size: 28px;
                line-height: normal;
            }
        }
    }
}

.naw {
    &--list {
        display: block;
        margin: 15px 0;

        &__item {
            margin: 0 10px 10px 0;
            width: 100%;
            padding: 0;
            font-size: 16px;
            line-height: normal;
            font-weight: 700;
            display: flex;
            flex-wrap: wrap;

            span {
                &:first-of-type {
                    width: 20px;
                    font-weight: 400;
                    color: $color-blue;
                }

                a {
                    text-decoration: none;
                }
            }
        }
    }
}

.page-bar-top {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: -20px 0 40px;
    justify-content: flex-end;
    align-items: center;

    @include media-breakpoint-down(xs) {
        margin: 0 0 20px;
    }

    form {
        margin: 0;
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .form-control {
            background-color: $color-white;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            height: 38px;
            min-width: 190px;
            max-width: 100%;

            @include media-breakpoint-down(md) {
                min-width: unset;
            }

            &:focus {
                background-color: $color-white;
            }
        }

        .btn-search {
            @include icon(search, $color-blue, 16px, 16px);
            width: 38px;
            height: 38px;
            background-color: transparent;
            border-color: transparent;
            position: absolute;
            right: 3px;
            top: 0;
            text-indent: -99999px;
            border-radius: 50%;
            display: block;
        }

        &__item {
            display: flex;
            align-items: center;
            position: relative;
            margin: 0 20px 0 0;

            @include media-breakpoint-down(md) {
                margin-bottom: 15px;
            }

            &:last-of-type {
                margin-right: 0;
            }

            .form-control {
                &--select {
                    &:not([size]):not([multiple]) {
                        height: 38px;
                        margin-left: 10px;
                    }
                }
            }
        }

        label {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            margin: 0 20px 0 0;
        }

        label,
        select {
            color: $color-blue;
        }
    }
}

.is-video::after {
    @include icon(youtube, $white, 16px, 16px);
    background-repeat: no-repeat;
    background-position: center center;
    content: '';
    width: 60px;
    height: 40px;
    background-size: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
}



.author {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 0;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    &__image {
        margin-right: 25px;
        border-radius: 50%;
        overflow: hidden;
        width: 96px;
        height: 96px;

        @include media-breakpoint-down(sm) {
            margin: 0 auto 20px;
        }
    }

    &__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 0;
    }

    &__info {
        max-width: 80%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        flex-direction: column;

        @include media-breakpoint-down(md) {
            max-width: unset;
            flex: 1;
            align-content: baseline;
        }

        .is-h3 {
            font-size: 32px;
            line-height: 43px;
            margin: 0;

            @include media-breakpoint-down(sm) {
                font-size: 25px;
            }
        }
    }
}


//** New Scss **//

.circle-animate {
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    bottom: 10vh;
    left: 10%;
    width: 244px;
    height: 244px;

    @include media-breakpoint-down(md) {
        width: 112px;
        height: 112px;
        bottom: 25vh;
        left: 15%;
    }

    @include media-breakpoint-down(sm) {
        width: 108px;
        height: 108px;
    }

    @include media-breakpoint-down(xs) {
        bottom: 0;
    }

    &--medium {
        width: 268px;
        height: 268px;
        left: 80%;
        bottom: 20vh;

        @include media-breakpoint-down(md) {
            width: 109px;
            height: 109px;
        }

        @include media-breakpoint-down(xs) {
            bottom: 6vh;
        }
    }

    &--small {
        width: 170px;
        height: 170px;
        left: 60%;
        bottom: 5vh;

        @include media-breakpoint-down(md) {
            width: 90px;
            height: 90px;
            bottom: 10vh;
        }

        @include media-breakpoint-down(sm) {
            width: 62px;
            height: 62px;
        }

        @include media-breakpoint-down(xs) {
            bottom: 0;
        }
    }
}

.svg-logo-footer {
    overflow: visible;
}

.block__youtube {
    margin: $default-margin 0;
}
