h1,
.is-h1 {
    color: $color-green;
    font-size: 100px;
    line-height: 90px;
    font-weight: 700;
    font-family: $heading-font;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        font-size: 70px;
        line-height: 60px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 60px;
        line-height: 55px;
    }
}

h2,
.is-h2 {
    color: $color-green;
    font-size: 38px;
    line-height: 48px;
    font-weight: 600;
    font-family: $condensed-font;
}

h3,
.is-h3 {
    color: $color-black;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    font-family: $condensed-font;
}

h4,
.is-h4 {
    color: $color-black;
    font-size: 26px;
    line-height: 30px;
    font-weight: 700;
    font-family: $heading-font;
}

h5,
.is-h5 {
    color: $color-black;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    font-family: $heading-font;
}

h6,
.is-h6 {
    color: $color-black;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    font-family: $heading-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    + .info {
        font-size: 14px;
        margin-top: -$default-margin;
    }
}
