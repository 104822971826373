.block {
    &--text-leftright {
        margin-top: $default-margin * 2;
        margin-bottom: $default-margin * 2;

        .highlights-overview__item {
            @include make-col(4);

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            img {
                width: 100%;
                margin: 45px auto;

                @include media-breakpoint-down(sm) {
                    margin: 25px auto;
                }
            }

            .video {
                width: 100%;
                margin: 0 auto 45px;

                @include media-breakpoint-down(sm) {
                    margin: 0 auto 25px;
                }

                .video-preview {
                    margin: 0 auto;

                    @include media-breakpoint-down(sm) {
                        margin: 0 auto;
                    }
                }
            }

            .highlights-overview__image {
                border-radius: 9999px;

                &--rotate {
                    transform: rotate(-20deg) scale(.8);
                    margin: 40px auto;

                    div & {
                        display: inline-block;
                    }
                }

                &--rotateright {
                    transform: rotate(20deg) scale(.8);
                    margin: 40px auto;

                    div & {
                        display: inline-block;
                    }
                }

                &--rotateleft {
                    transform: rotate(-20deg) scale(.8);
                    margin: 40px auto;

                    div & {
                        display: inline-block;
                    }
                }
            }


            &:first-of-type {
                @include make-col(8);

                @include media-breakpoint-down(sm) {
                    @include make-col(12);
                }

                .highlights-overview__image {
                    &--rotate {
                        transform: rotate(-20deg) scale(.8);
                        margin: 0 auto;

                        div & {
                            display: inline-block;
                        }
                    }
                }
            }

            &--wide {
                @include make-col(8);
                @include make-col-pull(-4);

                @include media-breakpoint-down(sm) {
                    @include make-col(12);
                    margin-left: 0;
                }
            }
        }
    }
}

.highlights-overview__item {
    $self: &;

    &--last {
        + #{ $self }--wide {
            @include make-col-pull(0);
        }
    }
}
