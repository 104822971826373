.richtext {
    display: flex;
    flex-direction: column;
    margin: 0 0 $default-margin;

    > :last-child {
        margin: 0;
    }

    p,
    address {
        font-weight: 400;
    }

    a:not(.btn) {
        color: $color-blue;
        outline: none;

        &:hover,
        &:focus {
            outline: none;
        }
    }

    .btn,
    .button {
        a {
            color: $color-white;
            text-decoration: none;
        }
    }

    b,
    strong {
        font-weight: 600;
    }

    i,
    em {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    blockquote {
        padding: 0 0 0 24px;
        margin: 25px 0 40px;
        border-left: 5px solid $text-color;

        p {
            font-size: 20px;
            line-height: 28px;
            font-style: italic;

            &:last-of-type {
                margin-bottom: 0;
            }

            &::before {
                content: '"';
            }

            &::after {
                content: '"';
            }
        }
    }

    pre {
        font-family: monospace, monospace;
    }

    table {
        width: 100%;

        tr {
            &:last-of-type {
                th,
                td {
                    border-bottom: 0;
                }
            }

            th,
            td {
                padding: 5px;
                border-bottom: 1px solid $color-gray;
            }
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    hr {
        height: 1px;
        background: $text-color;
        border: 0;
    }

    dl {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        dt,
        dd {
            padding: 5px;
            margin: 0;
            border-bottom: 1px solid $color-gray;
        }

        dt {
            flex: 0 0 40%;
            max-width: 40%;

            &:last-of-type {
                border-bottom: 0;
            }
        }

        dd {
            flex: 0 0 60%;
            max-width: 60%;

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
}
