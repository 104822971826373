// Gallery block

.block {
    &--gallery {
        margin: 0;
    }
}

.gallery {
    @include make-col(8);
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        @include make-col(12);
    }
}

.gallery-slider {
    $self: &;
    display: flex;
    justify-content: center;
    margin: 0 0 40px;
    padding: 0 30px;

    &__item {
        position: relative;
        display: block;
        flex: 0 0 100%;
        max-width: 100%;

        &:nth-child(1) {
            ~ #{ $self }__item {
                display: none;
            }
        }
    }

    &.tns-slider {
        justify-content: flex-start;

        #{ $self }__item {
            flex: unset;
            max-width: none;

            &:nth-child(1) {
                ~ #{ $self }__item {
                    display: block;
                }
            }
        }
    }
}

.gallery-slider-nav {
    display: flex;
    justify-content: center;
    margin: 0 0 40px;

    &.disabled {
        display: none;
    }

    a {
        display: flex;
        width: 35px;
        height: 35px;
        border: 2px solid $color-white;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 5px;
        transition: all .3s ease-in-out;

        &:hover,
        &:focus,
        &.active {
            border-color: $color-blue;
        }
    }
}

.gallery-overview {
    width: 83.33333%;
    margin: 0 auto 60px;
    column-count: 4;
    column-gap: 10px;

    @include media-breakpoint-down(md) {
        width: 100%;
        column-count: 3;
    }

    @include media-breakpoint-down(sm) {
        column-count: 2;
    }

    &__item {
        display: block;
        margin: 0 0 10px;

        a {
            display: block;
        }
    }
}
