// Newsletter block

.newsletter {
    @include make-col(8);
    display: flex;
    flex-direction: column;
    padding: 40px 20px 0;
    margin: 0 auto 40px;
    background-color: $color-white;


    @include media-breakpoint-down(sm) {
        @include make-col(12);
    }

    &__title {
        text-align: center;
    }

    &__content {
        text-align: center;

        .form-inline {
            text-align: left;
        }
    }
}
