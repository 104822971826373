// Anchor block

.block {
    &--anchor {
        position: sticky;
        top: 0;
        width: 100%;
        margin: 0;
        z-index: 998;
    }
}

.anchor {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    margin: 0 0 40px;

    &__content {
        padding: 20px 20px 0;

        .navigation {
            &--anchor {
                margin: 0 0 20px;
            }
        }
    }
}
