.navigation {
    $self: &;

    ul {
        margin: 0;

        li {
            display: block;
            padding: 0;
            margin: 0;

            a {
                display: block;
                color: $color-blue;
                padding: 6px 8px 4px;
                text-transform: uppercase;

                @include media-breakpoint-down(lg) {
                    display: inline-block;
                    padding: 6px 8px 4px;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            > ul {
                display: none;
            }

            &:hover,
            &:focus {
                > a {
                    background-color: $color-blue;
                    color: $color-white;
                    text-decoration: none;
                }
            }

            &.active {
                > a {
                    background-color: $color-blue;
                    color: $color-white;
                    text-decoration: none;
                    border-radius: 20px;

                    @include media-breakpoint-down(lg) {
                        background-color: $color-white;
                        color: $color-blue;
                    }
                }

                > ul {
                    display: block;
                }
            }

            &.has-subnav {
                position: relative;

                > .toggle-subnav {
                    @include icon(arrow, $color-blue, 10px, 7px);
                    position: absolute;
                    top: 10px;
                    right: 8px;
                    display: inline-block;
                    width: 10px;
                    height: 7px;
                    padding: 0;
                    transform: rotate(0deg);
                    transition: all .3s;

                    @include media-breakpoint-down(lg) {
                        @include icon(arrow, $color-white, 10px, 7px);
                        position: relative;
                        top: -2px;
                        left: -22px;
                    }
                }

                &:hover,
                &:focus {
                    > .toggle-subnav {
                        @include icon(arrow, $color-blue, 10px, 7px);
                    }
                }

                &.active {
                    > .toggle-subnav {
                        @include icon(arrow, $color-blue, 10px, 7px);
                    }
                }

                &.active,
                &.subnav-active {
                    > .toggle-subnav {
                        @include media-breakpoint-down(lg) {
                            transform: rotate(180deg);
                        }
                    }

                    > ul {
                        @include media-breakpoint-down(lg) {
                            display: block;
                        }
                    }
                }

                // scss-lint:disable MergeableSelector
                &.active.subnav-active {
                    > .toggle-subnav {
                        @include icon(arrow, $color-blue, 10px, 7px);
                        transform: rotate(0deg);
                    }

                    > ul {
                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }
                }
                // scss-lint:enable MergeableSelector
            }
        }
    }

    &--header {
        @include make-col-pull(0);
        margin: 0 auto;

        @include media-breakpoint-down(lg) {
            position: fixed;
            top: 0;
            left: 100%;
            bottom: 0;
            width: 100%;
            height: 100vh;
            padding: 75px 0 15px;
            margin: 0;
            overflow: auto;
            background-color: $color-white;
            transition: all .3s ease-in-out;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;

            @include media-breakpoint-down(lg) {
                display: block;
                padding: 0 30px;
                text-align: left;
            }

            li {
                margin: 0 5px;

                @include media-breakpoint-down(lg) {
                    display: block;
                    margin: 0;
                }

                a {
                    color: $color-blue;
                    font-size: 13px;
                    line-height: 15px;
                    font-weight: 600;

                    @include media-breakpoint-down(lg) {
                        color: $color-white;
                        font-size: 16px;
                        line-height: 18px;
                    }
                }

                > ul {
                    position: absolute;
                    left: -99999px;
                    display: block;
                    width: 200px;
                    padding: 0 5px;
                    background-color: $color-white;

                    @include media-breakpoint-down(lg) {
                        position: static;
                        display: none;
                        width: auto;
                        margin: 0;
                        padding: 0 0 0 20px;
                        background-color: transparent;
                    }

                    li {
                        display: block;
                        margin: 0;
                    }
                }

                &:hover,
                &:focus {
                    > a {
                        background-color: $color-blue;
                        color: $color-white;
                        border-radius: 20px;

                        @include media-breakpoint-down(lg) {
                            background-color: $color-white;
                            color: $color-blue;
                        }
                    }

                    > ul {
                        left: auto;
                        display: block;
                    }
                }

                &.has-subnav {
                    position: relative;

                    .navigation {
                        left: -99999px;

                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }

                    &:hover,
                    &:focus {
                        > a {
                            position: relative;

                            .toggle-subnav {
                                @include icon(arrow, $text-color, 10px, 7px);

                                @include media-breakpoint-down(lg) {
                                    @include icon(arrow, $color-blue, 10px, 7px);
                                }
                            }
                        }

                        .navigation {
                            left: 0;
                            display: flex;

                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                        }
                    }

                    &.active,
                    &.subnav-active {
                        > .toggle-subnav {
                            @include icon(arrow, $color-white, 10px, 7px);

                            @include media-breakpoint-down(lg) {
                                @include icon(arrow, $color-blue, 10px, 7px);
                            }
                        }

                        .navigation {
                            @include media-breakpoint-down(lg) {
                                display: block;
                                width: 100%;
                            }
                        }
                    }

                    &.active.subnav-active {
                        .navigation {
                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        > ul {
            > li {
                ul {
                    background-color: transparent;
                    margin-top: 2px;

                    li {
                        > .toggle-subnav {
                            display: none;
                        }

                        &:hover {
                            > .toggle-subnav {
                                display: none;
                            }

                            li {
                                left: 15px;
                                display: block;

                                @include media-breakpoint-down(lg) {
                                    left: 0;
                                }
                            }
                        }
                    }
                }

                &.has-subnav {
                    > a {
                        padding-right: 22px;

                        @include media-breakpoint-down(lg) {
                            padding-right: 27px;
                        }
                    }

                    &:hover,
                    &:focus {
                        > .toggle-subnav {
                            @include icon(arrow, $color-white, 10px, 7px);

                            @include media-breakpoint-down(lg) {
                                @include icon(arrow, $color-blue, 10px, 7px);
                            }
                        }
                    }
                }
            }
        }
    }

    &--subnav {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        flex-wrap: wrap;
        width: 40%;
        padding: 0;
        margin: 1px 0 0;

        @include media-breakpoint-down(lg) {
            position: static;
            border-bottom: 0;
        }

        #{ $self }__content {
            flex: 1;
            max-width: 1170px;
            padding: 0 15px;
            margin: 0 auto;

            @include media-breakpoint-down(lg) {
                padding: 0;
                max-width: none;
            }
        }

        #{ $self }__highlight {
            flex: 0 0 268px;
            max-width: 268px;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        ul {
            padding: 0;
            margin: 0 -15px;

            @include media-breakpoint-down(lg) {
                margin: 0;
                padding: 0 0 0 20px;
            }

            li {
                position: relative;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
                margin: 0;

                @include media-breakpoint-down(lg) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0;
                }

                a {
                    display: inline-block;
                    font-size: 13px;
                    line-height: 15px;
                    padding: 6px 8px 4px;
                    background-color: $color-blue;
                    color: $color-white;
                    border-radius: 20px;
                    margin-bottom: 0;
                    white-space: nowrap;

                    @include media-breakpoint-down(lg) {
                        font-size: 16px;
                        line-height: 18px;
                        padding: 6px 8px 4px;
                    }
                }

                &:hover,
                &:focus {
                    > a {
                        background-color: $color-green;
                    }
                }

                &.has-subnav {
                    position: relative;

                    > a {
                        font-weight: 700;
                    }

                    > .toggle-subnav {
                        display: none;
                        left: 5px;
                    }
                }

                &.active {
                    > ul {
                        @include media-breakpoint-down(lg) {
                            display: block;
                        }
                    }
                }

                ul {
                    display: none;
                    position: static;
                    width: 100%;
                    padding: 0;
                    margin: 0 0 10px;

                    @include media-breakpoint-down(lg) {
                        display: block;
                    }

                    li {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding: 0;

                        @include media-breakpoint-down(lg) {
                            padding: 0 0 0 10px;
                        }

                        a {
                            font-weight: 400;

                            @include media-breakpoint-down(lg) {
                                width: 100%;
                                white-space: break-spaces;
                            }
                        }

                        &.has-subnav {
                            > a {
                                font-weight: 400;
                            }

                            > .toggle-subnav {
                                display: block;
                            }
                        }

                        &.active {
                            > ul {
                                @include media-breakpoint-down(lg) {
                                    display: block;
                                }
                            }
                        }

                        ul {
                            display: none;
                            padding: 0 0 0 20px;
                            list-style-type: disc;
                            margin: 0;

                            li {
                                display: list-item;
                            }
                        }
                    }
                }
            }
        }

        .highlight {
            $root: &;
            padding: 0;
            border-radius: 0;
            box-shadow: unset;
            margin: 0;
            display: flex;
            border-left: 1px solid $color-gray;

            &--simple {
                .highlight {
                    &__content {
                        padding: 0 20px;
                    }
                }
            }
        }
    }

    &--footer {
        font-family: $mono-font;

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                display: inline-block;
                margin: 0 5px;

                a {
                    color: $color-white;
                    font-size: 14px;
                    line-height: 24px;
                    padding: 0;
                }
            }
        }
    }

    &--anchor {
        margin: 0;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -5px;

            li {
                display: inline-block;
                margin: 0 5px;

                a {
                    color: $color-black;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: none;
                }

                &:hover,
                &:focus {
                    > a {
                        color: $color-blue;
                        text-decoration: none;
                    }
                }

                &.active {
                    > a {
                        color: $color-blue;
                    }
                }
            }
        }
    }

    &--side {
        margin: 0 0 $default-margin;

        h2 {
            font-size: 25px;
            line-height: normal;
        }

        ul {
            li {
                border-bottom: 1px solid $color-gray-1;

                &:last-of-type {
                    border-bottom: 0;
                }

                ul {
                    padding: 0 0 0 20px;
                    border-top: 1px solid $color-gray-1;
                }
            }
        }
    }
}

.primary-navigation-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(lg) {
                display: block;
                left: 0;
                background-color: $color-blue;
            }
        }
    }
}

.primary-search-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(lg) {
                top: 70px;
                height: calc(100vh - 70px);
            }
        }
    }
}
