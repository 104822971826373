.block--quote {

    .wrapper {
        margin-bottom: $default-block-margin;
        margin-top: $default-block-margin;
        padding: 1.5rem 0;

        @include media-breakpoint-down(md) {
            margin-bottom: 0;
            margin-top: 0;
        }

        .d-flex {
            margin-left: $default-block-margin;
            margin-right: $default-block-margin;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-evenly;
            align-items: center;
            gap: 4rem;
            flex: 1 1;

            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
                gap: 2rem;
                margin-left: 0;
                margin-right: 0;
            }

            &.reverse {
                flex-direction: row-reverse;

                @include media-breakpoint-down(md) {
                    flex-direction: column-reverse;
                }
            }

            .quote-block {
                max-width: 70%;

                @include media-breakpoint-down(md) {
                    max-width: initial;
                    padding: 0 1.5rem;
                }
            }

            image {
                flex-shrink: 0;
                padding: 2rem;
                max-width: 40%;
            }

            .subtitle {
                font-weight: 600;
            }

            .title{
                position: relative;
            }

            .quote {
                border-left: 2px solid $color-green;
                font-style: italic;
                padding-left: 1rem;
            }

            .name {
                font-weight: 600;
            }

            .function {
                color: $color-black-off;
            }

        }
    }
}
