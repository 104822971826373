// Events block

.events-highlights {
    @include make-row();
    margin: 0 -15px;

    &__events {
        @include make-col(4);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }

    &__highlights {
        @include make-col(8);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }
}

.event {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    &[href] {
        color: $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            #{ $self }__content {
                .is-h5 {
                    color: $color-blue;
                }
            }
        }
    }

    &__image {
        flex: 0 0 96px;
        max-width: 96px;
        padding: 0 10px;

        picture {
            border-radius: 5px;
            overflow: hidden;
            margin: 0 0 $default-margin;
        }
    }

    &__content {
        flex: 1;
        padding: 0 10px;

        .is-h5 {
            color: $color-blue;
            font-size: 16px;
            line-height: 24px;

            + .is-text {
                margin-top: -$default-margin;
            }
        }
    }
}
