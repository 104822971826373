.block {
    &--certificates {
        margin: 0 0 45px;
    }
}

.certificates {
    display: flex;
    flex-wrap: wrap;

    div {
        @include make-col(4);

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }
    }
}
