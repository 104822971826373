.header {
    position: sticky;
    top: -86px;
    flex-shrink: 0;
    margin: 0 0 30px;
    transition: all .3s ease-in-out;
    z-index: 999;
    background-color: $color-white;

    &__search {
        position: relative;
        display: block;
        width: 100%;
        padding: 15px;
        margin: -77px 0 0;
        background-color: $color-gray-1;
        transition: all .3s ease-in-out;
        z-index: 1;

        .form-search {
            margin: 0;
        }

        .search-suggestions {
            position: absolute;
            display: none;
            top: 77px;
            left: 15px;
            right: 15px;
            background-color: $color-white;
            border: 1px solid $color-gray;
            opacity: .9;
            margin: 0;

            &::after {
                position: absolute;
                left: 50%;
                top: 0;
                display: block;
                width: 16px;
                height: 16px;
                content: '';
                transform: rotate(45deg);
                border-top: 1px solid $color-gray;
                border-left: 1px solid $color-gray;
                background-color: $color-white;
                margin: -8px 0 0 -8px;
            }

            &.active {
                display: block;
            }

            li {
                display: block;
                border-bottom: 1px solid $color-gray;

                &:last-child {
                    border-bottom: 0;
                }

                a {
                    display: block;
                    padding: 7px 15px;
                    font-size: 16px;
                    line-height: 23px;
                }
            }
        }
    }

    &__content {
        @extend .container;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 15px;

        @include media-breakpoint-down(md) {
            padding: 15px 30px;
        }
    }

    .logo {
        @include icon(logo, $color-blue, 250, 26);
        display: block;
        width: 250px;
        height: 26px;
        order: 2;
        margin: 0 auto 0 0;

        @include media-breakpoint-down(md) {
            @include icon(logo-md, $color-blue, 130, 53);
            width: 130px;
            height: 53px;
            margin: 0 auto 0 0;
            z-index: 1;
        }

        img {
            display: none;
            width: auto;
            height: 100%;
            max-height: 100%;
        }
    }

    .navigation {
        &--header {
            display: flex;
            order: 3;
        }
    }

    .nav-toggle {
        @include icon(close, transparent, 20px, 20px);
        display: none;
        width: 50px;
        height: 50px;
        padding: 7px;
        margin: 3px 10px 3px 0;
        transition: all .3s ease-in-out;
        order: 2;
        z-index: 5;
        position: relative;

        @include media-breakpoint-down(lg) {
            display: block;
            margin: 0;
        }

        &:hover,
        &:focus {
            span,
            &::before,
            &::after {
                background-color: $color-blue;
            }
        }

        span,
        &::before,
        &::after {
            display: block;
            width: 30px;
            height: 4px;
            margin: 6px auto;
            content: '';
            background-color: $color-blue;
            transition: all .3s;
            border-radius: 2px;
            backface-visibility: hidden;
        }

        &.active {
            @include icon(close, $color-white, 20px, 20px);

            &:hover,
            &:focus {
                @include icon(close, $color-white, 20px, 20px);
            }

            &::before {
                transform: rotate(45deg) translate(7px, 7px);
            }

            &::after {
                transform: rotate(-45deg) translate(7px, -7px);
            }

            span,
            &::before,
            &::after {
                opacity: 0;
                background-color: $color-white;
            }
        }
    }

    .search-toggle {
        @include icon(search, $color-blue, 12px, 12px);
        display: block;
        width: 12px;
        height: 12px;
        padding: 7px;
        margin: 7px 0 3px 10px;
        font-size: 0;
        transition: all .3s ease-in-out;
        order: 4;

        @include media-breakpoint-down(lg) {
            @include icon(search, $color-white, 12px, 12px);
            margin: 0 30px;
            padding: 6px 27px 4px 8px;
            font-size: 16px;
            line-height: 18px;
            color: $color-white;
            text-transform: uppercase;
            width: auto;
            height: auto;
            background-position: right center;
            position: absolute;
            bottom: 50px;
        }

        &:hover,
        &:focus {
            @include icon(search, $color-blue, 12px, 12px);

            @include media-breakpoint-down(lg) {
                @include icon(search, $color-white, 12px, 12px);
                background-position: right center;
            }
        }

        &.active {
            @include icon(close, $color-blue, 12px, 12px);

            @include media-breakpoint-down(lg) {
                @include icon(close, $color-white, 12px, 12px);
                background-position: right center;
            }

            &:hover,
            &:focus {
                @include icon(close, $color-black, 12px, 12px);

                @include media-breakpoint-down(lg) {
                    @include icon(close, $color-white, 12px, 12px);
                    background-position: right center;
                }
            }
        }
    }

    .language-toggle {
        @include icon(globe, $color-blue, 20px, 20px);
        display: block;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0 0 0 5px;
        font-size: 0;
        order: 10;

        @include media-breakpoint-down(md) {
            margin: 0 0 0 10px;
        }

        &:hover,
        &:focus {
            @include icon(globe, $color-black, 20px, 20px);
        }
    }
}

.header-fixed {
    .header {
        background-color: $color-white;
        box-shadow: 0 1px 3px rgba($color-black, .1);
    }
}

.scroll-up {
    .header {
        top: 0;
    }
}

.primary-navigation-active {
    .header {
        .logo {
            @include icon(logo-md, $color-white, 130, 53);
        }
    }
}

.primary-search-active:not(.scroll-down) {
    .header {
        margin: 0 0 -47px;

        &__search {
            margin: 0;
        }
    }
}
