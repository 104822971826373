// Brands block

.block {
    &--team {
        margin: 0 0 100px;
    }
}

.team {
    &__top {
        .is-h2 {
            font-size: 52px;
            line-height: 60px;
            text-transform: uppercase;

            @include media-breakpoint-down(md) {
                font-size: 46px;
                line-height: 50px;
            }
        }
    }

    &__selector {
        display: inline-block;
        position: relative;

        @include media-breakpoint-down(md) {
            display: block;
            line-height: 40px;
        }

        &-list {
            display: none;
            width: 450px;
            max-width: calc(100vw - 60px);
            font-size: 20px;
            line-height: 20px;
            font-weight: normal;
            position: absolute;
            top: 70px;
            left: 0;
            background-color: $color-blue;
            padding: 30px;
            border-radius: 40px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;

            li {
                @include make-col(5.5);
                margin-bottom: 10px;
                padding: 0;
                background: none;

                &:nth-child(2n) {
                    @include make-col(6.5);
                }
            }

            a {
                font-family: $mono-font;
                text-transform: none;
                display: inline-block;
                font-size: 14px;
                line-height: 16px;
                border-radius: 20px;
                padding: 6px 17px 4px;
                color: $color-white;
                position: relative;

                &:hover {
                    background-color: $color-white;
                    color: $color-green;
                    text-decoration: none;
                }

                &.active {
                    padding-right: 35px;
                    background-color: $color-white;
                    color: $color-green;

                    &::after {
                        @include icon(close, $color-green, 10px, 7px);
                        display: block;
                        content: '';
                        width: 10px;
                        height: 7px;
                        position: absolute;
                        right: 10px;
                        top: 11px;
                    }
                }
            }
        }
    }

    &__toggler {
        font-size: 52px;
        line-height: 60px;
        border-radius: 60px;
        padding: 0 65px 0 25px;
        background-color: $color-blue;
        color: $color-white;
        position: relative;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        vertical-align: top;

        @include media-breakpoint-down(md) {
            font-size: 36px;
            line-height: 40px;
            padding: 4px 38px 2px 15px;
        }

        &:hover {
            background-color: $color-green;
            color: $color-white;
            text-decoration: none;
        }

        &::after {
            @include icon(arrow, $color-white, 30px, 21px);
            content: '';
            width: 30px;
            height: 21px;
            position: absolute;
            right: 20px;
            top: 22px;
            transition: all .3s;

            @include media-breakpoint-down(md) {
                @include icon(arrow, $color-white, 22px, 15px);
                width: 22px;
                height: 15px;
                top: 16px;
                right: 10px;
            }
        }

        &.active {
            &::after {
                transform: rotate(180deg);
                transition: all .3s;
                top: 18px;

                @include media-breakpoint-down(md) {
                    top: 15px;
                }
            }
        }
    }


    &__list {
        @include make-row();
        justify-content: center;

        &-person {
            @include make-col(3);
            margin-top: 45px;
            text-align: center;

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }

            &.first-row {
                @include make-col-pull(-1.5);

                @include media-breakpoint-down(md) {
                    @include make-col-pull(0);
                }
            }

            &.last-row {
                @include make-col-push(1.5);

                @include media-breakpoint-down(md) {
                    @include make-col-push(0);
                }
            }

            a {
                &:hover {
                    h3 {
                        color: $color-green;
                    }
                }
            }

            img {
                border-radius: 50%;
                width: 95%;
                height: 95%;
            }

            .is-h4 {
                margin: 15px 0 0;
                text-transform: uppercase;
                font-family: $mono-font;
                font-size: 16px;
                line-height: 18px;
                font-weight: 600;
            }

            p {
                margin: 0;
                font-family: $mono-font;
                font-size: 16px;
                line-height: 18px;
            }

            .icon {
                width: 22px;
                height: 22px;

                &--phone {
                    @include icon(phone, $color-green, 14px, 14px);
                }

                &--email {
                    @include icon(email, $color-green, 14px, 14px);
                }
            }
        }
    }
}
