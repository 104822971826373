.block {
    &--form-image {
        margin: 0;
    }
}

.form-image {
    $self: &;
    @include make-row();
    margin: 0 0 40px;

    &__image {
        @include make-col(6);

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    &__content {
        @include make-col(6);
        padding: 30px 30px 0;
        background-color: $color-black-off;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        > * {
            color: $color-white;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .richtext {
            margin: 0 0 30px;
        }
    }

    &--reversed {
        flex-direction: row-reverse;
    }
}

.form {
    &--download {
        .form-group {
            .form-control {
                color: $color-white;
                padding-left: 0;
                padding-right: 0;
                border: 0;
                border-bottom: 1px solid $color-white;
                border-radius: 0;
                background: transparent;

                // Webkit
                &::-webkit-input-placeholder {
                    color: $color-white;
                    font-style: normal;
                }

                // Firefox 4-18
                &::-moz-placeholder {
                    color: $color-white;
                    font-style: normal;
                }

                // IE10+
                &:-ms-input-placeholder {
                    color: $color-white;
                    font-style: normal;
                }
            }

            .btn {
                color: $text-color;
                text-transform: uppercase;
                padding: 12px 40px 12px 15px;

                &::after {
                    @include icon(arrow, $text-color, 16px, 16px);
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    display: block;
                    width: 16px;
                    height: 16px;
                    content: '';
                    margin: -8px 0 0;
                    transform: rotate(-90deg);
                }
            }
        }
    }
}
