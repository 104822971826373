.tns-outer {
    display: flex;
    flex-direction: column;

    .tns-controls {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        order: 1;
        outline: none;

        button {
            @include icon(arrow, $text-color, 10px, 20px);
            display: block;
            width: 40px;
            height: 40px;
            font-size: 0;
            border: 0;
            padding: 0;
            margin: 0;
            outline: none;

            &[disabled] {
                @include icon(arrow, $text-color, 10px, 20px);
            }

            &:first-child {
                transform: rotate(180deg);
            }

            &:last-child {
                transform: rotate(0);
            }
        }
    }
}

