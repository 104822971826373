// Contactbar block

.block {
    &--contactbar {
        margin: 45px 0 0;
    }
}

.contactbar {
    $self: &;

    &__top {
        padding: 45px 0;
        background-color: $color-green;
        color: $color-white;

        .is-h2 {
            font-family: $narrow-font;
            font-size: 50px;
            line-height: 50px;
            color: $color-white;
            text-transform: uppercase;
        }
    }

    .tabs-panel {
        #{ $self }__item {
            display: flex;
            flex-wrap: wrap;

            &-map {
                @include make-col(5);

                @include media-breakpoint-down(md) {
                    @include make-col(12);
                }

                .map {
                    width: 100%;
                    height: 100%;
                    min-height: 450px;
                    background-position: center center;
                    background-size: cover;
                }
            }

            &-info {
                @include make-col(7);
                padding: 15px 30px;

                @include media-breakpoint-down(md) {
                    @include make-col(12);
                }

                .is-h2 {
                    color: $color-blue;
                    font-family: $narrow-font;
                }
            }

            &-contacts {
                @include make-row();
                max-width: 900px;
                min-height: 600px;
                align-items: center;
            }

            &-person {
                @include make-col(4);
                text-align: center;
                font-family: $mono-font;
                font-size: 16px;
                line-height: 18px;
                margin-bottom: 45px;

                @include media-breakpoint-down(sm) {
                    @include make-col(12);
                }

                img {
                    width: 85%;
                    border-radius: 50%;
                }

                .is-h4 {
                    font-family: $mono-font;
                    font-size: 16px;
                    line-height: 18px;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                }

                p {
                    max-width: 180px;
                    margin: 0 auto;
                }

                .icon {
                    width: 22px;
                    height: 22px;

                    &--phone {
                        @include icon(phone, $color-green, 14px, 14px);
                    }

                    &--email {
                        @include icon(email, $color-green, 14px, 14px);
                    }
                }
            }
        }
    }
}

.tabs {
    max-width: 900px;
    margin: 0;

    &-title {
        display: inline-block;
        margin-bottom: 5px;
        padding: 0;
        background: none;

        a {
            display: block;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 16px;
            border-radius: 20px;
            padding: 8px 15px 6px;
            background-color: $color-green;
            color: $color-white;
            cursor: pointer;

            &:hover {
                background-color: lighten($color-green, 4);
                text-decoration: none;
            }
        }

        &.is-active {
            a {
                background-color: $color-white;
                color: $color-green;

                &:hover {
                    background-color: $color-white;
                    color: $color-green;
                }
            }
        }
    }
}

.tabs-panel {
    display: none;

    &.is-active {
        display: block;
    }
}
