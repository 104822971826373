// Reviews block

.block {
    &--reviews {
        margin: 0;
    }

    &--review-scores {
        margin: 0;
    }
}

.review {
    display: flex;
    flex-direction: column;

    .richtext {
        + .is-h3 {
            margin-top: -30px;
        }
    }
}

.reviews {
    @include make-col(8);
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        @include make-col(12);
    }

    &__title {
        text-align: center;
    }

    &__buttons {
        text-align: center;
    }
}

.reviews-slider {
    $self: &;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0 30px;

    &__item {
        position: relative;
        display: block;
        flex: 0 0 100%;
        max-width: 100%;

        &:nth-child(1) {
            ~ #{ $self }__item {
                display: none;
            }
        }
    }

    &.tns-slider {
        justify-content: flex-start;

        #{ $self }__item {
            flex: unset;
            max-width: none;

            &:nth-child(1) {
                ~ #{ $self }__item {
                    display: block;
                }
            }
        }
    }
}

.review-scores {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__buttons {
        text-align: center;
    }
}

.review-scores-overview {
    @include make-row();
    justify-content: center;
    margin: 0 -15px;

    &__item {
        @include make-col(2.4);
        padding: 0 15px;

        @include media-breakpoint-down(sm) {
            @include make-col(6);
        }
    }
}

.review-score {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;

    .is-h2,
    .is-h3 {
        margin: 0 0 10px;
    }

    .list {
        margin: 0 0 10px;
    }

    &--large {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        text-align: center;

        .list {
            margin: 22px 15px 10px 0;
        }

        p {
            flex: 0 0 100%;
            font-size: 14px;
            margin: 0 0 10px;
        }

        a {
            color: $color-blue;

            &:hover,
            &focus {
                color: $color-blue;
                text-decoration: underline;
            }
        }
    }
}
