// Activehosted block

.block {
    &--activehosted {
        margin: 0;
        padding: 15px 0 35px;
        background-color: $color-green;
    }
}

.activehosted {
    color: $color-white;

    // Request sample form
    // scss-lint:disable ImportantRule
    // scss-lint:disable SelectorFormat
    ._form_13 {
        ._form {
            &_element {
                &:nth-child(1) {
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }

    ._form {
        width: 100% !important;

        &-content {
            min-height: 150px;
            margin: -20px !important;
            width: calc(100% + 40px) !important;
            background-color: $color-green;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &_element {
            color: $color-white;
            float: none !important;
            flex: 0 0 24% !important;
            max-width: 24% !important;
            margin: 0 !important;

            @include media-breakpoint-down(md) {
                flex: 0 0 100% !important;
                max-width: 100% !important;
                margin-bottom: 20px !important;
            }

            &._field24 {
                flex: 0 0 90px !important;
                max-width: 90px !important;
                display: flex;
                justify-content: center;

                @include media-breakpoint-down(md) {
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                    justify-content: flex-start;
                }
            }
        }

        &-label {
            color: $color-white;
        }

        ._field-wrapper {
            input {
                width: 100% !important;
                border: 0 !important;
                border-radius: 20px !important;
            }
        }

        ._checkbox-radio {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            > span {
                display: block;
                margin-left: 10px;

                label {
                    margin: 0;
                }
            }

            ._error-inner {
                flex: 0 0 100%;
                position: absolute;
                bottom: -20px;
            }
        }

        ._error {
            width: calc(100% - 13px);

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            &-arrow {
                display: none;
            }

            &-inner {
                width: 100%;
                line-height: 120%;
                padding: 0 !important;
                background: none !important;
                text-align: left !important;
                color: lighten($color-pink, 30%) !important;
            }
        }

        ._button-wrapper {
            flex: 0 0 80px;
            max-width: 80px;
            margin: 0 !important;
            display: flex;
            align-items: center;

            ._submit {
                border-radius: 20px !important;
                padding: 8px 15px !important;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
    // scss-lint:enable SelectorFormat
    // scss-lint:enable ImportantRule
}
