button {
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;

    &:hover,
    &:focus {
        outline: none;
    }
}

.btn,
.button {
    $self: &;
    position: relative;
    display: inline-block;
    color: $color-white;
    font-size: 16px;
    line-height: 18px;
    padding: 8px 15px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    border: 0;
    background-color: $color-blue;
    border-radius: 9999px;

    a {
        color: $color-white;
        text-decoration: none;
    }

    + .btn {
        margin-left: 15px;
    }

    &:hover,
    &:focus {
        color: $color-white;
        text-decoration: none;
        background-color: $color-green;
        border-color: darken($color-green, 10%);
    }

    &--big {
        font-size: 22px;
        line-height: 30px;
        padding: 12px 20px;
    }

    &--white {
        color: $color-blue;
        border-color: $color-white;
        background-color: $color-white;

        &:hover,
        &:focus {
            color: $color-blue;
            border-color: darken($color-white, 10%);
            background-color: darken($color-white, 10%);
        }
    }

    &--green {
        border-color: $color-green;
        background-color: $color-green;

        &:hover,
        &:focus {
            border-color: darken($color-green, 10%);
        }
    }

    &--transparent {
        color: $color-blue;
        background-color: transparent;

        &:hover,
        &:focus {
            color: darken($color-blue, 10%);
            border-color: darken($color-blue, 10%);
            background-color: transparent;
        }

        &#{ $self }--white {
            color: $color-white;
            border-color: $color-white;

            &:hover,
            &:focus {
                color: darken($color-white, 10%);
                border-color: darken($color-white, 10%);
            }
        }
    }

    &--link {
        color: $color-blue;
        font-size: 16px;
        line-height: 18px;
        padding: 0;
        border: 0;
        background-color: transparent;

        &:hover,
        &:focus {
            color: $text-color;
            background-color: transparent;
        }
    }

    &--icon {
        padding-left: 40px;

        &::after {
            position: absolute;
            top: 9px;
            left: 10px;
            display: block;
            width: 20px;
            height: 20px;
            content: '';
        }

        &.btn {
            &--big {
                padding-left: 48px;

                &::after {
                    top: 13px;
                    left: 13px;
                    width: 28px;
                    height: 28px;
                }
            }

            &--social-share {
                &::after {
                    @include icon(share, $color-white, 20px, 20px);
                }

                &.btn {
                    &--big {
                        &::after {
                            @include icon(share, $color-white, 28px, 28px);
                        }
                    }
                }
            }
        }
    }

    &--to-top {
        @include icon(arrow, $color-white, 20px, 20px);
        position: fixed;
        right: 50px;
        bottom: 50px;
        display: block;
        width: 45px;
        height: 45px;
        font-size: 0;
        z-index: 9999;
        opacity: 0;
        transform: rotate(-180deg);
        border-radius: 50%;
        transition: all .3s ease-in-out;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &:hover,
        &:focus {
            background-color: darken($color-blue, 10%);
        }

        &.active {
            opacity: 1;
        }
    }

    &--whatsapp {
        background-color: $color-whatsapp;
        padding-left: 40px;

        &:hover,
        &:focus {
            background-color: darken($color-whatsapp, 10%);
        }

        &::after {
            @include icon(whatsapp, $color-white, 24px, 24px);
            content: '';
            height: 24px;
            left: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
        }
    }

    &--social-share {
        color: $text-color;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        padding: 5px 0 5px 36px;
        text-transform: uppercase;
        background-color: transparent;
        border: 0;

        &:hover,
        &:focus {
            color: $color-blue;
            text-decoration: none;
            background-color: transparent;

            &::after {
                @include icon(share, $color-blue, 20px, 20px);
            }
        }

        &::after {
            @include icon(share, $text-color, 20px, 20px);
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 32px;
            height: 32px;
            content: '';
        }
    }

    &--arrow {
        @include icon(arrow-right, $white, 20px, 20px);
        background-position: calc(100% - 10px) center;
        padding-right: 35px;
    }

    &--link-arrow {
        background: none;
        color: $color-blue;

        &:focus,
        &:hover {
            background: none;
            color: $color-green;

            &::after {
                background-color: $color-green;
            }
        }

        &::after {
            @include icon(arrow-right, $white, 20px, 20px);
            background-color: $color-blue;
            background-position: center 40%;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            content: '';
            display: block;
            float: right;
            margin: -5px 0 0 10px;
        }
    }

    &--interest-special {
        cursor: pointer;
        
        &:focus,
        &:hover {
            background-color: $color-white;
            color: $color-blue;
        }
    }

    &--link-arrow-special {
        cursor: pointer;
        background: none;
        color: $color-blue;

        &:focus,
        &:hover {
            background: none;

            &::after {
                background-color: $color-white;
                @include icon(arrow-right, $color-green, 20px, 20px);
            }
        }


        &::after {
            @include icon(arrow-right, $white, 20px, 20px);
            background-color: $color-blue;
            background-position: center 40%;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            content: '';
            display: block;
            float: right;
            margin: -5px 0 0 10px;
        }
    }

    &--sticky {
        border-radius: 1rem 1rem 0 0;
        position: fixed;
        right: 0;
        top: 50%;
        z-index: calc($zindex-modal-backdrop - 10);
        transform: rotate(270deg) translateX(50%);
        transform-origin: 100% 100%;
        white-space: nowrap;
        margin: 0;
    }
}
