// Categories block

.block {
    &--categories {
        margin: 0;
    }
}

.categories {
    .is-h1 {
        @include media-breakpoint-up(md) {
            margin-top: 40px;
        }
    }

    &__list {
        @include make-row();
        justify-content: center;

        &-item {
            @include make-col(3);
            margin-top: 45px;
            text-align: center;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-up(lg) {
                &:nth-child(7n+5) {
                    @include make-col-pull(-1.5);
                }

                &:nth-child(7n) {
                    @include make-col-push(1.5);
                }
            }

            a {
                &:hover {
                    text-decoration: none;

                    h3 {
                        color: $color-green;
                    }
                }
            }

            img {
                border-radius: 50%;
                width: 95%;
            }

            h3 {
                font-family: $mono-font;
                margin: 15px 0 0;
                font-weight: normal;
                text-transform: uppercase;
                font-size: 18px;
                line-height: 20px;
            }

            p {
                margin: 0;
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
}
