*,
*::before,
*::after {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
}

html,
body {
    font-family: $text-font;
    color: $text-color;
    font-weight: 400;
    font-size: $text-size;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    background: $color-white;
}

body {
    display: flex;
    flex-direction: column;
}

// scss-lint:disable ImportantRule
html {
    &.primary-navigation-active {
        @include media-breakpoint-down(md) {
            overflow: hidden;

            .btn {
                &--to-top {
                    display: none !important;
                }
            }
        }
    }
}
// scss-lint:enable ImportantRule

img:not([src]) {
    visibility: hidden;
}

img[data-src],
img[data-srcset] {
    display: block;
    min-height: 1px;
}

::-moz-selection {
    background-color: $color-blue-1;
}

::selection {
    background-color: $color-blue-1;
}

.search-highlight,
.simplesearch-highlight {
    background-color: rgba($color-green, .1);
}

.date {
    font-weight: 300;
    font-size: 15px;
}

iframe {
    display: block;
    max-width: 100%;
    border: 0;
}

a,
.link {
    color: $color-blue;
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $text-color;
        outline: none;
        text-decoration: underline;
    }
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

.date-label {
    background: $color-black;
    color: $color-white;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    text-align: left;
    padding: 5px 10px;
}

p,
blockquote,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
form,
table,
section,
aside,
article,
dl,
hr {
    margin: 0 0 $default-margin;
}

table {
    width: 100%;
    border-collapse: collapse;

    &.table-wrapper {
        margin: 0 0 $default-margin;
        overflow-x: scroll;

        table {
            margin: 0;

            tr {
                th,
                td {
                    @include media-breakpoint-down(md) {
                        min-width: 200px;
                    }
                }
            }
        }
    }
}

ul,
ol {
    padding: 0;
    list-style-type: none;
}

img {
    height: auto;
    max-width: 100%;

    &[loading] {
        width: auto;
    }
}

[data-animate] {
    &.is-loaded {
        > * {
            transform: translateY(100px);
            visibility: hidden;
            opacity: 0;
            transition: transform 1s ease-in-out, visibility 1s ease-in-out, opacity 1s ease-in-out;
        }

        &.is-visible {
            > * {
                transform: translateY(0);
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

[data-animate="left-to-right"] {
    &.is-loaded {
        > * {
            transform: translateX(-100px);
        }

        &.is-visible {
            > * {
                transform: translateX(0);
            }
        }
    }
}

[data-animate="right-to-left"] {
    &.is-loaded {
        > * {
            transform: translateX(100px);
        }

        &.is-visible {
            > * {
                transform: translateX(0);
            }
        }
    }
}

.location-image {
    &--hidden {
        display: none;
    }
}

.info {
    display: block;
    font-style: italic;
    margin: 0 0 $default-margin;
    color: $text-color;
}

.video-wrapper {
    text-align: center;
    margin: 0 0 $default-margin;

    &__inner {
        position: relative;
        display: block;
        padding: 25px 0 56.25%;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.video {
    text-decoration: none;
    position: relative;
    display: block;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba($color-green, .20);
    }

    &::after {
        content: 'Play';
        text-transform: uppercase;
        background-color: $color-green;
        color: $color-white;
        border: 2px solid $color-green;
        border-radius: 40px;
        font-size: 45px;
        line-height: 45px;
        padding: 5px 25px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    &:focus::after,
    &:hover::after {
        color: $color-green;
        background-color: rgba($color-white, .7);
    }
}

.icon {
    display: inline-block;
    height: 40px;
    width: 40px;

    &--check {
        @include icon(check, $color-blue, 40px, 40px);
    }

    &--phone {
        @include icon(phone, $color-white, 14px, 14px);
    }

    &--instagram {
        @include icon(instagram, $color-white, 18px, 18px);
    }

    &--linkedin {
        @include icon(linkedin, $color-white, 18px, 18px);
    }

    &--email {
        @include icon(email, $color-white, 18px, 18px);
    }
}

picture,
figure {
    display: block;
}

section {
    &.main {
        position: relative;
        flex: 1 0 auto;
        margin: 0;

        > .wrapper {
            > h1 {

                @include media-breakpoint-up(md) {
                    margin-top: 40px;
                }
            }
        }
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            @include icon(leaf, $color-green, 18px, 18px);
            background-position: 0 7px;
            padding-left: 35px;
        }
    }

    ol {
        list-style: none;
        padding: 0;
        padding-left: 35px;
        counter-reset: num;
        position: relative;

        li {
            &::before {
                display: block;
                content: counter(num);
                counter-increment: num;
                color: $color-white;
                background-color: $color-green;
                border-radius: 50%;
                font-size: 12px;
                line-height: 12px;
                width: 18px;
                height: 18px;
                padding: 3px 0 0 1px;
                margin-top: 7px;
                position: absolute;
                left: 0;
                text-align: center;
            }
        }
    }
}

.wrapper {
    @extend .container;
    @include clearfix();

    @include media-breakpoint-down(md) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.visible-tablet {
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
    }
}

.hidden-tablet {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.visible-mobile {
    display: none;

    @include media-breakpoint-down(xs) {
        display: block;
    }
}

.hidden-mobile {
    @include media-breakpoint-down(xs) {
        display: none;
    }
}
