.block {
    &--article {
        $self: &;
        margin: 0 0 60px;
    }
}

.article {
    &__title {
        @include make-col(7);
        display: flex;
        justify-content: center;
        margin: 40px auto;
        flex-direction: column;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            margin: 0 0 20px;
        }

        .is-h3 {
            margin-bottom: 10px;
            display: block;
            text-align: center;
            line-height: 55px;
        }

        .is-h1 {
            margin-bottom: 10px;
            display: block;
        }

        .info-publish {
            display: block;
            margin-top: -10px;
            font-size: 14px;
            line-height: 28px;
        }

        &--left {
            margin-left: 0;
        }
    }

    &__content {
        @include make-col(8);
        display: flex;
        flex-direction: column;
        margin: 0 auto $default-margin;
        padding: 0 5px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .richtext {
            margin: 0;
        }

        .form-search {
            @include media-breakpoint-up(lg) {
                max-width: 85%;
            }
        }

        &--small {
            text-align: center;
            padding: 0 10%;

            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }

        &--left {
            margin-left: 0;
        }
    }

    &__image {
        @include make-col(10);
        display: flex;
        flex-direction: column;
        margin: 0 auto 40px;
        padding: 0 5px;
    }

    &__tags {
        @include make-col(8);
        display: flex;
        justify-content: center;
        margin: 0 auto 20px;
        flex-direction: column;
        border-bottom: 1px solid rgba($color-blue, .20);
        padding: 0 5px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .form {
            align-self: center;
            min-width: 45%;
        }
    }

    &__author {
        @include make-col(8);
        padding: 0 5px;
        margin: 0 auto 20px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}
