// Highlights block

.block {
    &--highlights {
        margin: 0;
    }
}

.highlights {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__buttons {
        text-align: center;
    }
}

.producthighlightbuttons {
    display: flex;
    flex-direction: row;
    margin-left: auto;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        margin-left: unset;
        margin-right: auto
    }
}

.highlight {
    $self: &;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    .date-label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
    }

    &__image {
        @include make-col(6.5);
        margin-top: $default-margin;
        margin-bottom: $default-margin;
        align-self: self-start;
        position: relative;

        @include media-breakpoint-down(sm) {
            @include make-col(11);
            margin-top: 0;
        }

        a {
            display: block;

            &.video {
                position: relative;

                &::after {
                    @include icon(play, $color-white, 60px, 60px);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: 60px;
                    height: 60px;
                    margin: -30px 0 0 -30px;
                    content: '';
                }

                &:hover,
                &:focus {
                    &::after {
                        @include icon(play, $color-blue, 60px, 60px);
                    }
                }
            }
        }

        + #{ $self }__content {
            @include media-breakpoint-down(sm) {
                margin-top: -$default-margin;
            }
        }
    }

    &__content {
        @include make-col(6.5);
        @include make-col-pull(1);
        position: relative;
        margin-bottom: $default-margin;
        padding: 30px 30px 0;
        background-color: $color-white;
        align-self: self-end;

        @include media-breakpoint-down(sm) {
            @include make-col(11);
            @include make-col-pull(0);
            align-self: flex-end;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .richtext {
            margin: 0 0 30px;

            + .read-more {
                margin-top: -30px;
            }
        }

        .list {
            &--downloads {
                margin: 0 0 20px;
            }
        }
    }

    &--reversed {
        flex-direction: row-reverse;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        #{ $self }__image {
            @include make-col-pull(1);
            margin-top: 0;

            @include media-breakpoint-down(sm) {
                @include make-col-pull(0);
                align-self: flex-end;
            }

            + #{ $self }__content {
                @include media-breakpoint-down(sm) {
                    margin-top: -$default-margin;
                }
            }
        }

        #{ $self }__content {
            @include make-col-pull(0);
            margin-top: $default-margin;

            @include media-breakpoint-down(sm) {
                align-self: flex-start;
                margin-top: 0;
            }
        }
    }

    &--small {
        flex-direction: column;

        #{ $self }__image {
            @include make-col(11);
            margin-top: 0;

            + #{ $self }__content {
                margin-top: -120px;
            }
        }

        #{ $self }__content {
            @include make-col(11);
            @include make-col-pull(0);
            align-self: flex-end;
        }

        &#{ $self }--reversed {
            #{ $self }__image {
                @include make-col-pull(0);
                align-self: flex-end;

                + #{ $self }__content {
                    margin-top: -120px;
                }
            }

            #{ $self }__content {
                align-self: flex-start;
                margin-top: 0;
            }
        }
    }

    &--simple {
        flex-direction: column;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        #{ $self }__content {
            @include make-col(12);
            align-self: normal;
            margin-left: 0;
            padding: 0;
            margin-bottom: 0;
            background: transparent;

            p {
                color: $text-color;
            }
        }

        #{ $self }__image {
            @include make-col(12);
            margin: 0 0 $default-margin;

            + #{ $self }__content {
                margin-top: -$default-margin * .5;
            }
        }
    }

    &--style-1 {
        #{ $self }__content {
            color: $color-white;
            background-color: $color-blue;

            > * {
                color: $color-white;
            }

            a:not(.btn) {
                color: $color-white;
                text-decoration: underline;

                &:hover,
                &:focus {
                    color: $color-white;
                    text-decoration: none;
                }
            }

            .btn {
                color: $color-blue;
                border-color: $color-white;
                background-color: $color-white;

                &:hover,
                &:focus {
                    color: $color-blue;
                    border-color: darken($color-white, 10%);
                    background-color: darken($color-white, 10%);
                }

                &--link {
                    color: $color-white;
                    border-color: transparent;
                    background-color: transparent;

                    &:hover,
                    &:focus {
                        color: $color-white;
                        border-color: transparent;
                        background-color: transparent;
                    }
                }

                &--transparent {
                    color: $color-white;
                    border-color: $color-white;
                    background-color: transparent;

                    &:hover,
                    &:focus {
                        color: darken($color-white, 10%);
                        border-color: darken($color-white, 10%);
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.highlights-overview {
    $self: &;
    @include make-row(12);
    margin: 0 -15px;

    &__item {
        @include make-col(6);
        padding: 0 15px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            margin-bottom: 40px;
            order: unset;
        }

        &--last {
            order: 999;
        }
    }

    &--three-columns {
        #{ $self }__item {
            @include make-col(4);

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    &--four-columns {
        #{ $self }__item {
            @include make-col(3);

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }
}
