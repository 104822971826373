.block {
    &--together {
        padding: 50px 0 80px;
        overflow: hidden;
        background: rgba($color-gray-2, .2);
    }
}

.together {
    &__title {
        .is-h2 {
            text-transform: uppercase;
            font-size: 70px;
            line-height: 70px;
            margin-bottom: 10px;

            @include media-breakpoint-down(sm) {
                font-size: 55px;
                line-height: 55px;
            }
        }

        .is-h3 {
            font-family: $text-font;
            font-size: 24px;
            line-height: 26px;
            color: $color-blue;
        }
    }

    &__content {
        margin-top: 100px;

        .tns-ovh {
            overflow: visible;
        }

        .tns-outer {
            @include media-breakpoint-down(xs) {
                width: 80%;
            }

            .tns-controls {
                position: absolute;
                top: 86px;
                right: 0;

                @include media-breakpoint-down(sm) {
                    display: none;
                }

                button {
                    @include icon(chevron, $color-green, 20px, 20px);
                    background-position: 55% center;
                    border: 1px solid $color-green;
                    border-radius: 50%;
                    margin-right: 15px;

                    &:focus,
                    &:hover {
                        @include icon(chevron, $color-white, 20px, 20px);
                        background-color: $color-green;
                    }
                }
            }
        }
    }

    &__buttons {
        text-align: right;
        margin: 80px 0 0;
    }

    &-slider {
        &__item {
            font-size: 20px;
            line-height: 26px;
            color: $color-black;
            display: block;
            padding: 30px;
            box-shadow: 0 0 10px rgba($color-gray, .5);
            border: 1px solid $color-white;
            margin-right: 35px;
            background-color: $color-white;

            &:focus,
            &:hover {
                text-decoration: none;
                box-shadow: 0 0 10px rgba($color-gray-2, .5);
                border: 1px solid $color-gray-1;
                color: $color-black;
            }

            img {
                display: block;
                width: 250px;
                height: auto;
                margin: 0 auto 50px;
                border-radius: 50%;
            }

            p {
                margin: 0;
                max-height: 75px;
                overflow: hidden;
            }

            .is-h3 {
                font-family: $narrow-font;
                font-weight: 600;
                color: $color-green;
                font-size: 32px;
                line-height: 32px;
                margin-bottom: 25px;
            }
        }
    }
}
