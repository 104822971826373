.hubspot-form {
    $self: &;
    @include make-row();
    margin: 0 -15px 60px;

    &--right {
        #{ $self }__form {
            order: 3;
        }

        #{ $self }__content {
            order: 2;
        }
    }

    &--center {
        #{ $self }__form {
            @include make-col(12);
            order: 3;
        }

        #{ $self }__content {
            @include make-col(12);
            order: 2;
        }
    }

    &__title {
        @include make-col(12);
        padding: 0 15px;
        order: 1;
    }

    &__form {
        @include make-col(6);
        padding: 0 15px;
        order: 2;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            order: 3;
        }
    }

    &__content {
        @include make-col(6);
        padding: 0 15px;
        order: 3;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            order: 2;
        }
    }
}
