// Elements block (ingredients, brands, private label)

.block {
    &--elements {
        margin: 0 0 40px;
    }
}

.elements {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 680px;
    width: 800px;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        height: 630px;
        width: 660px;
    }

    @include media-breakpoint-down(sm) {
        height: 420px;
        width: 410px;
    }

    @include media-breakpoint-down(xs) {
        display: block;
        height: 250px;
        width: 285px;
        margin-top: 10px;
    }

    &__title {
        font-family: $condensed-font;
        font-weight: 600;
        text-transform: uppercase;
        color: $color-blue;
        font-size: 70px;
        line-height: 70px;
        max-width: 540px;

        @include media-breakpoint-down(lg) {
            font-size: 60px;
            line-height: 60px;
        }

        @include media-breakpoint-down(md) {
            font-size: 55px;
            line-height: 55px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        align-content: stretch;
        justify-content: center;
        position: absolute;
        width: 310px;
        height: 310px;
        top: 90px;
        left: 490px;
        background-color: $color-blue;
        border-radius: 155px;
        color: $white;
        font-family: $narrow-font;
        font-weight: 600;
        font-size: 50px;
        line-height: 46px;
        text-transform: uppercase;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            top: 90px;
            left: 420px;
        }

        @include media-breakpoint-down(sm) {
            width: 180px;
            height: 180px;
            font-size: 30px;
            line-height: 28px;
            top: 37px;
            left: 225px;
        }

        @include media-breakpoint-down(xs) {
            width: 120px;
            height: 120px;
            font-size: 20px;
            line-height: 16px;
            top: 30px;
            left: 160px;
        }

        &:hover,
        &:focus {
            color: $color-white;
            text-decoration: none;

            &::before {
                display: block;
            }

            img {
                opacity: 1;
            }
        }

        &::before {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($color-black, .2);
        }

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            border-radius: 155px;
            transition: opacity .2s ease;
        }

        span {
            max-width: 220px;
            text-align: center;
            position: relative;

            @include media-breakpoint-down(sm) {
                max-width: 160px;
            }
        }

        &:first-of-type {
            width: 530px;
            background-color: $color-white;
            border: 1px solid $color-green;
            color: $color-green;
            transform: rotate(-30deg);
            top: 55px;
            left: -15px;

            @include media-breakpoint-down(md) {
                top: 55px;
                left: -85px;
            }

            @include media-breakpoint-down(sm) {
                width: 240px;
                height: 150px;
                top: 25px;
                left: -5px;
            }

            @include media-breakpoint-down(xs) {
                width: 160px;
                height: 120px;
                font-size: 20px;
                line-height: 16px;
                top: 10px;
                left: 0;
            }

            &:hover,
            &:focus {
                color: $color-white;
                text-decoration: none;

                img {
                    opacity: 1;
                }
            }

            span {
                display: block;
                transform: rotate(30deg);
            }

            img {
                @include media-breakpoint-down(md) {
                    width: 135%;
                    max-width: unset;
                }
            }
        }

        &:last-of-type {
            top: 315px;
            left: 272px;
            background-color: $color-green;

            @include media-breakpoint-down(md) {
                top: 315px;
                left: 202px;
            }

            @include media-breakpoint-down(sm) {
                top: 165px;
                left: 95px;
            }

            @include media-breakpoint-down(xs) {
                top: 120px;
                left: 76px;
            }

            &:hover,
            &:focus {
                img {
                    opacity: 1;
                }
            }
        }
    }

    &__hover {
        display: none;
    }
}
