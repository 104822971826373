.overview {
    $self: &;
    @include make-row();

    &__buttons {
        @include make-col(12);
        display: flex;
        flex-wrap: wrap;
        padding-left: 15px;
        padding-right: 15px;
        justify-content: flex-end;
    }

    &__sidebar {
        @include make-col(3);
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__content {
        @include make-col(9);
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }

    &__empty {
        background: $color-gray-1;
        display: block;
        text-align: center;
        margin: 0 15px;
        padding: 15px;
        width: 100%;

        * {
            margin: 0;
        }
    }

    &--team {
        @include make-row();
        margin: 0 -20px;

        #{ $self }__item {
            @include make-col(6);
            padding: 0 20px;

            @include media-breakpoint-down(xs) {
                @include make-col(12);
            }
        }
    }
}
