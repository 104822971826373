@font-face {
    font-family: 'GT America Standard';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Black.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Black.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Bold.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Bold.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Medium.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Medium.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Regular.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Regular.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Thin.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Thin.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Ultra-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Ultra-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Black-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Black-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Bold-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Bold-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Medium-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Medium-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Regular-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Regular-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Thin-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Thin-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Standard';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Standard-Ultra-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Standard-Ultra-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Black.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Black.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Bold.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Bold.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Medium.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Medium.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Regular.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Regular.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Thin.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Thin.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Ultra-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Ultra-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Black-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Black-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Bold-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Bold-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Medium-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Medium-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Regular-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Regular-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Thin-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Thin-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Mono-Ultra-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Mono-Ultra-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Black.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Black.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Bold.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Bold.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Medium.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Medium.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Regular.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Regular.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Thin.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Thin.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Ultra-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Ultra-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Black-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Black-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Bold-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Bold-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Medium-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Medium-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Regular-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Regular-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Thin-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Thin-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Extended';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Extended-Ultra-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Extended-Ultra-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Black.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Black.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Bold.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Bold.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Medium.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Medium.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Regular.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Regular.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Thin.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Thin.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Ultra-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Ultra-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Black-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Black-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Bold-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Bold-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Medium-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Medium-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Regular-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Regular-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Thin-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Thin-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Expanded';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Expanded-Ultra-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Expanded-Ultra-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Black.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Black.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Bold.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Bold.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Medium.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Medium.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Regular.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Regular.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Thin.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Thin.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Ultra-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Ultra-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Black-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Black-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Bold-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Bold-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Medium-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Medium-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Regular-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Regular-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Thin-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Thin-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Condensed';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Condensed-Ultra-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Condensed-Ultra-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Black.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Black.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Bold.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Bold.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Medium.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Medium.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Regular.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Regular.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Thin.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Thin.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Ultra-Light.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Ultra-Light.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Black-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Black-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Bold-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Bold-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Medium-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Medium-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Regular-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Regular-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Thin-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Thin-Italic.woff') format('woff');
}

@font-face {
    font-family: 'GT America Compressed';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('../../fonts/GT-America/GT-America-Compressed-Ultra-Light-Italic.woff2') format('woff2'),
         url('../../fonts/GT-America/GT-America-Compressed-Ultra-Light-Italic.woff') format('woff');
}
