// scss-lint:disable ImportantRule
// scss-lint:disable SelectorFormat
// scss-lint:disable IdSelector
// scss-lint:disable DeclarationOrder
#modal-interest {
    .modal {
        &-header {
            padding-bottom: 0;
            border: 0;
        }

        &-body {
            padding: 0;
        }
    }

    .col {
        &-left {
            background: $color-green;
            color: $white;

            h3,
            .is-h3,
            .close {
                color: $white;
            }

            .wrapper {
                padding: 2rem;
            }
        }

        &-right {
            ._form {
                margin: 0 !important;

                label {
                    display: inline-block;
                }

                ._submit {
                    width: 100% !important;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .col {
            &-left {
                .wrapper {
                    padding-top: 0;
                }
            }

            &-right {
                .modal-header {
                    display: none;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .col {
            &-left {
                .modal-header {
                    display: none;
                }
            }
        }
    }
}
