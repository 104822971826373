.breadcrumb {
    display: block;
    padding: 15px 0;
    margin: -30px 0 30px;
    background-color: $color-gray-3;
    border-radius: 0;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &-list {
        display: flex;
        padding: 0;
        margin: 0;
        font-size: 13px;
    }

    &-item {
        color: rgba($color-black-light, .5);

        a {
            color: $color-blue;
        }

        &.active {
            a {
                color: $color-black;
                text-decoration: none;
            }
        }

        + .breadcrumb {
            &-item {
                &::before {
                    color: $color-black;
                }
            }
        }
    }
}
