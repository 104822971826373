// Call to action block

.block {
    &--cta-block,
    &__cta {
        margin: 0;

        &.bg-green,
        &.bg-light-green {
            background-color: $color-green;
            color: $color-white;
            padding: 40px 0;

            h1,
            .is-h1,
            h2,
            .is-h2 {
                color: $color-white;
            }

            .btn {
                &--green {
                    background-color: $color-blue;
                }
            }
        }

        p {
            margin-bottom: 15px;
        }

        input[type="text"],
        input[type="email"] {
            border-radius: 20px;
            padding: 5px 15px;
            border: 1px solid $color-green;
            background-color: $color-white;
        }
    }
}

.cta-block {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 45px;

    &__image {
        @include make-col(3);

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }

    &__content {
        @include make-col(9);
        padding: 0 0 25px 25px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding: 25px 0;
        }
    }
}

.newsletter-block {
    .cta-block {
        &__content {
            flex: 0 0 50%;
            max-width: 50%;
        }

        &__image {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
