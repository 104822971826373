.pagination {
    margin: 0 0 50px;
    border-radius: 0;

    &-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        margin: 0;

        .page-item {
            display: inline-block;
            margin: 0 5px;
            padding: 0;
            background: none;

            &:first-child,
            &:last-child {
                .page-link {
                    border-radius: 50%;
                }
            }

            &.active {
                .page-link {
                    font-weight: 600;
                    background-color: $color-green;
                    border-color: $color-green;
                }
            }

            &.control {
                @include media-breakpoint-down(md) {
                    display: none;
                }

                .page-link {
                    @include icon(arrow, $color-white, 17px, 17px);
                    background-color: $color-green;
                    text-indent: -99999px;

                    &:hover,
                    &:focus {
                        background-color: $color-green, 10%;
                    }
                }

                &.prev {
                    .page-link {
                        transform: rotate(90deg);
                    }
                }

                &.next {
                    .page-link {
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        .page-link {
            display: block;
            width: 40px;
            height: 40px;
            padding: 7px 0;
            color: $color-green;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            overflow: hidden;
            border-radius: 50%;
            border: 1px solid $color-green;

            &:not(span) {
                &:hover,
                &:focus {
                    background-color: $color-green;
                    color: $color-white;
                }
            }
        }
    }
}
