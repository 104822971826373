.block {
    &--highlight-products {
        background-color: $color-green;
        padding: 60px 0 30px;
        margin-bottom: 85px;
    }
}


//scss-lint:disable PropertySpelling

.highlight-products {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;

    &__image {
        @include make-col(6);
        flex: 0 0 auto;
        padding: 0 15px;
        margin-bottom: 30px;
        position: relative;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            text-align: center;
        }

        .label {
            position: absolute;
            top: 40px;
            right: 0;
            background-color: $color-blue;
            color: $color-white;
            text-transform: uppercase;
            font-size: 35px;
            line-height: normal;
            font-weight: 800;
            text-align: center;
            border-radius: 2em;
            padding: 13px 30px;
            rotate: -15deg;
        }

        picture {
            position: relative;

            @include media-breakpoint-down(md) {
                display: inline-block;
            }

            img {
                border-radius: 50%;
                overflow: hidden;
            }
        }
    }

    &__content {
        @include make-col(6);
        padding: 0 15px;
        margin-bottom: 30px;
        margin-left: auto;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        > * {
            color: $color-white;
        }

        .is-h2 {
            text-transform: uppercase;
            font-size: 48px;
            font-family: $condensed-font;
            font-weight: 600;
            margin-bottom: 50px;
        }

        .read-more {
            margin-top: auto;
            text-align: right;
        }

        .btn {
            &--link-arrow {
                &:focus,
                &:hover {
                    background: none;
                    color: $color-white;

                    &::after {
                        @include icon(arrow-right, $color-green, 20px, 20px);
                        background-color: $color-white;
                    }
                }
            }
        }
    }
}

.product-list-view {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;

    &__item {
        @include make-col(6);
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}

.product-highlight {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;

    &__image {
        flex: 0 0 120px;
        max-width: 120px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;

        picture {
            overflow: hidden;
            border-radius: 50%;

            img {
                scale: 1;
                transition: scale .3s ease-in-out;
            }
        }
    }

    &__content {
        flex: 1 0 auto;
        max-width: calc(100% - 120px);
        text-transform: uppercase;
        color: $color-white;
        text-decoration: none;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 18px;
        line-height: 18px;
        font-family: $condensed-font;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            margin-bottom: 0;
        }
    }

    &[href]:hover,
    &[href]:focus {
        text-decoration: none;

        #{ $self }__image {
            picture img {
                scale: 1.2;
            }
        }

        #{ $self }__content {
            color: $color-blue;
        }
    }
}
//scss-lint:enable PropertySpelling
