// Content block

.block {
    &--content-block {
        margin: 0;
    }
}

.content-block {
    $self: &;
    @include make-col(8);
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        @include make-col(12);
    }

    &__buttons {
        text-align: center;
    }

    &--two-columns {
        #{ $self }__content {
            @include make-row();
            margin: 0 -15px;

            .richtext {
                @include make-col(6);
                padding: 0 15px;

                @include media-breakpoint-down(sm) {
                    @include make-col(12);
                }
            }
        }
    }
}
