// Hero block

.block {
    &--hero {
        background: none;
        margin: 0 0 40px;
        height: calc(100vh - 190px);
        min-height: 550px;
        overflow: hidden;
    }

    &--hero-green {
        background-color: $color-green;
        color: $color-white;
        padding: 80px 0 50px;
    }
}

.hero {
    $self: &;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 190px);
    min-height: 550px;

    &__title {
        font-family: $condensed-font;
        font-size: 80px;
        line-height: 71px;
        color: $color-green;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            font-size: 70px;
            line-height: 65px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 60px;
            line-height: 54px;
        }

        @media (max-width: 410px) {
            font-size: 50px;
            line-height: 45px;
        }
    }

    &__content {
        width: 75%;
        text-align: center;
        margin-top: -85px;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .richtext {
            align-items: center;
            font-size: 28px;
            line-height: 38px;
            font-weight: 900;

            + .is-h2 {
                margin-top: -20px;
            }
        }

        .is-h2 {
            font-size: $text-size;
            line-height: $line-height;
        }
    }

    &--dark {
        #{ $self }__content {
            > * {
                color: $color-black;

                @include media-breakpoint-down(sm) {
                    color: $text-color;
                }
            }

            .is-h2 {
                color: $color-black;

                @include media-breakpoint-down(sm) {
                    color: $color-black;
                }
            }
        }
    }

    &--light {
        height: auto;

        #{ $self }__content {
            margin: 0;
            text-align: left;

            > * {
                color: $color-white;
            }

            h3 {
                font-size: 90px;
                line-height: 80px;
                font-weight: 600;
                max-width: 850px;
                color: $color-white;

                @include media-breakpoint-down(sm) {
                    font-size: 55px;
                    line-height: 52px;
                }
            }
        }
    }
}
