// Products block

.block {
    &--products {
        margin: $default-block-margin - $default-margin 0;

        @include media-breakpoint-down(xs) {
            margin: 0 0 ($default-block-margin - $default-margin) - 20px;
        }
    }
}

.products {
    display: flex;
    flex-direction: column;

    &__buttons {
        text-align: center;
    }

    &__container {
        width: 100%;
        max-width: 100%;
        position: relative;
        z-index: 0;
        overflow: hidden;
    }

    &__content {
        @extend .wrapper;
        position: relative;
    }

    &__title {
        max-width: 700px;
        text-transform: uppercase;

        + &__slider {
            margin-top: -$default-margin;
        }
    }

    &__slider {
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        &__item {
            margin-bottom: $default-margin;
        }

        .tns-ovh {
            overflow: visible;
        }
    }

    &__controls {
        position: absolute;
        bottom: -25px;
        right: 5%;
        outline: none;
    }
}

.product {
    $self: &;
    display: flex;
    flex-direction: column;
    margin: 0 0 $default-margin;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;

    &__image {
        margin: 0 0 $default-margin;

        + #{ $self }__content {
            margin-top: -$default-margin;
        }

        img {
            border-radius: 50%;
        }
    }

    &__content {
        padding: 20px 20px 0;

        .is-h5 {
            font-weight: 700;
            display: flex;
            align-items: center;

            .label {
                margin-left: 7px;
            }

            + .richtext {
                margin-top: -$default-margin;
            }
        }
    }
}

// scss-lint:disable ImportantRule
.products-slider {
    $self: &;

    &__item {
        margin-right: 20px;
    }

    &.tns-slider {
        > .tns-item {
            width: calc(15.83%) !important;
        }
    }
}
// scss-lint:enable ImportantRule

.products-list {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__item {
        @include make-col(4);

        .product {
            color: $text-color;
            text-decoration: none;
        }
    }
}
