// Logo's block

.block {
    &--logos {
        margin: 0;
    }
}

.logos {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__buttons {
        text-align: center;
    }
}

// scss-lint:disable ImportantRule
.logos-slider {
    $self: &;
    display: flex !important;
    justify-content: center;
    margin: 0 0 40px;
    padding: 0 30px;

    &__item {
        position: relative;
        display: block;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            flex: 0 0 33.3333333333%;
            max-width: 33.3333333333%;

            &:nth-child(3) {
                ~ #{ $self }__item {
                    display: none;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            flex: 0 0 50%;
            max-width: 50%;

            &:nth-child(2) {
                ~ #{ $self }__item {
                    display: none;
                }
            }
        }

        &:nth-child(6) {
            ~ #{ $self }__item {
                display: none;
            }
        }

        a {
            position: relative;
            display: block;

            &::after {
                position: absolute;
                left: 50%;
                right: 50%;
                bottom: 0;
                height: 2px;
                content: '';
                opacity: 0;
                z-index: 1;
                background-color: $color-blue;
                transition: all .3s ease-in-out;
            }

            &:hover,
            &:focus {
                &::after {
                    left: 0;
                    right: 0;
                    opacity: 1;
                }
            }
        }
    }

    &.owl-carousel {
        .owl-stage {
            #{ $self }__item {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .owl-nav {
            width: auto;
            left: 0;
            right: 0;

            button {
                &.owl-prev,
                &.owl-next {
                    @include icon(arrow, $color-gray-2, 20px, 20px);
                    width: 20px;
                }
            }
        }
    }
}
// scss-lint:enable ImportantRule
