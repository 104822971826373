// Slider block

.block {
    &--slider {
        margin: 0;
    }
}

.slider-item {
    $self: &;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 40px;

    &__image {
        position: relative;

        a {
            display: block;

            &.video {
                position: relative;

                &::after {
                    @include icon(play, $color-white, 20px, 20px);
                    position: absolute;
                    left: 10px;
                    bottom: 10px;
                    display: block;
                    width: 30px;
                    height: 30px;
                    content: '';
                }

                &:hover,
                &:focus {
                    &::after {
                        @include icon(play, $color-blue, 20px, 20px);
                    }
                }
            }
        }
    }

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40%;
        padding: 0 15px;
        transform: translateY(-50%);

        @include media-breakpoint-down(md) {
            left: 30%;
            width: 60%;
        }

        @include media-breakpoint-down(sm) {
            position: static;
            width: 100%;
            padding: 40px 40px 0;
            transform: none;
        }
    }

    &--dark {
        #{ $self }__content {
            > * {
                color: $color-black;

                @include media-breakpoint-down(sm) {
                    color: $text-color;
                }
            }

            .is-h2 {
                color: $color-black;

                @include media-breakpoint-down(sm) {
                    color: $color-black;
                }
            }
        }
    }

    &--light {
        #{ $self }__content {
            > * {
                color: $color-white;

                @include media-breakpoint-down(sm) {
                    color: $text-color;
                }
            }

            .is-h2 {
                color: $color-white;

                @include media-breakpoint-down(sm) {
                    color: $color-black;
                }
            }
        }
    }
}

.slider {
    $self: &;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

    &__item {
        position: relative;
        display: block;
        flex: 0 0 100%;
        max-width: 100%;

        &:nth-child(1) {
            ~ #{ $self }__item {
                display: none;
            }
        }
    }

    &.tns-slider {
        justify-content: flex-start;

        #{ $self }__item {
            flex: unset;

            &:nth-child(1) {
                ~ #{ $self }__item {
                    display: block;
                }
            }
        }
    }
}

.slider-nav {
    position: relative;
    float: right;
    display: flex;
    justify-content: center;
    margin: -81px 0 40px;
    padding: 0 15px;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &.disabled {
        display: none;
    }

    a {
        display: flex;
        width: 100px;
        height: 84px;
        overflow: hidden;
        margin: 0 5px;
        opacity: .5;
        transition: all .3s ease-in-out;

        &:hover,
        &:focus,
        &.active {
            opacity: 1;
        }
    }
}
